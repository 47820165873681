export function initFieldMap(fields){
    const m = {}
    fields?.forEach(field=>{
      if (field.Enabled){
        m[field.FieldName] = field
      }
    })
    return m
  }

 export function getEmailPrefix(email) {
    const parts = email.split('@');
    return parts.length > 1 ? parts[0] : email; // If '@' exists, return the part before it, else return the whole string
  }

 export function formatTime(durationInSeconds) {
      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.floor((durationInSeconds % 3600) / 60);
      const seconds = durationInSeconds % 60;
  
      let formattedTime = '';
  
      // Add hours component if it's non-zero
      if (hours > 0) {
          formattedTime += `${hours}:`;
      }
  
      // Add minutes component if it's non-zero or there are hours
      if (minutes >= 10 || hours > 0) {
          formattedTime += `${String(minutes).padStart(2, '0')}:`;
      } else {
        formattedTime += `${String(minutes).padStart(1, '0')}:`;
      }
  
      // Always add seconds component
      formattedTime += String(seconds).padStart(2, '0');
  
      return formattedTime;
  }
  
  export function toUrlSafeBase64(input) {
    return btoa(input) // standard Base64
      .replace(/\+/g, '-') // make URL-safe
      .replace(/\//g, '_')
      .replace(/=+$/, ''); // remove any trailing '='
  }

  export function fromUrlSafeBase64(encoded) {
    // Convert URL-safe base64 to standard base64
    const base64 = encoded
      .replace(/-/g, '+')
      .replace(/_/g, '/')
      .padEnd(encoded.length + (4 - encoded.length % 4) % 4, '=');
  
    return atob(base64); // Decodes to raw string
  }