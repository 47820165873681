import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { dvSrv } from "../App"
import { BillingInfo } from './Billing';
import { Templates } from './Template';
import moment from "moment-timezone";
import { SessionContext } from "../App";
import { toast } from "react-toastify"
import ReactJson from "@microlink/react-json-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStopCircle, faWarning } from '@fortawesome/free-solid-svg-icons';

const TimeZoneNameMap = {
  "UTC": "UTC",
  "Eastern": "America/New_York",
  "Central": "America/Chicago",
  "Mountain": "America/Denver",
  "Pacific": "America/Los_Angeles",
  "Alaska": "America/Anchorage",
  "Hawaii": "Pacific/Honolulu",  
}
export const CurrentTimeFormat = "L hh:mm a"

export const TimeZoneAbbr = (session) =>{
  let tzAbbr = "UTC"
//  const session = useContext(SessionContext)
  if (session && session.data && session.data.Settings) {
    let tzSetting = session.data.Settings.find(us=>us.FieldName === "TimeZone")
    if (tzSetting !== null) {
      let tzs = tzSetting.FieldValues.split(',')
      tzAbbr = (tzSetting.Value === null || tzSetting.Value < 1 || tzSetting.Value > tzs.length) ?
      "UTC" : tzs[tzSetting.Value -1]
    }    
  }

  return tzAbbr
}

export const CurrentTimeZoneName = (session) => {
  return TimeZoneNameMap[TimeZoneAbbr(session)] 
}

export const TimeStringForTimeZone = (session, t) => {
  return timeForTimeZone(t, TimeZoneAbbr(session)).format(CurrentTimeFormat)
}

const timeForTimeZone = (t, z) => {
  return moment(t).tz(TimeZoneNameMap[z], false)
}

export const Settings = () => {
  
  return (
    <>
    <h1 class="mb-3">Account Settings</h1>
    <AdminSettings />
    {/* <hr />
    <div class="row">
      <div class="col-6">
        <LabelSettings />
      </div>
      <div class="col-6">
      </div>
    </div> */}
  </>
  )
}

export const AdminSettings = () => {
  
  return (
    <>
    <div class="row">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active internal-tab" id="report-tab" data-bs-toggle="tab" data-bs-target="#report" type="button" role="tab" aria-controls="report" aria-selected="false">Report Template</button>
        </li>
        {/* <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button" role="tab" aria-controls="review" aria-selected="false">QA Review Template</button>
        </li> */}
        <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="policy-tab" data-bs-toggle="tab" data-bs-target="#policy" type="button" role="tab" aria-controls="policy" aria-selected="false">Account Policy</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="acct-tab" data-bs-toggle="tab" data-bs-target="#acct" type="button" role="tab" aria-controls="acct" aria-selected="false">Other Settings</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="billing-tab" data-bs-toggle="tab" data-bs-target="#billing" type="button" role="tab" aria-controls="billing" aria-selected="false">Billing</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="report" role="tabpanel" aria-labelledby="report-tab">
          <Templates />
        </div>
        {/* <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
          <Template type={2} />
        </div> */}
        <div class="tab-pane fade" id="policy" role="tabpanel" aria-labelledby="policy-tab">
          <AccountPolicy />
          {/* <h3>Account logo</h3>
          <h3>Centralized device list and passwords control</h3>
          <h3>Limit users to access their own files only. Yes/No</h3> */}
        </div>
        <div class="tab-pane fade" id="acct" role="tabpanel" aria-labelledby="acct-tab">
          <AccountSettings />
        </div>
        <div class="tab-pane fade" id="billing" role="tabpanel" aria-labelledby="billing-tab">
          <BillingInfo />
        </div>
      </div>
    </div>
    {/* <ReactJson src={data} collapsed={1} /> */}
    </>
  )
}

export const LabelSettings = () => {
    const [fileLabels, setFileLabels] = useState([])
    const [newLabelName, setNewLabelName] = useState('')

    useEffect(() => {
        axios.get(dvSrv + '/api/setting/label', {withCredentials: true})  // Replace with your actual API endpoint
            .then(response => {
                setFileLabels(response.data);
            })
            .catch(error => console.error("Error fetching labels: ", error));
    }, [])

    const handleRemove = (name) => {
        axios.delete(dvSrv + `/api/setting/label/${name}`, {withCredentials: true}).
        then(() => {
            // Refresh the list or filter out the deleted item locally
            setFileLabels(prevLabels => prevLabels.filter(label => label.Name !== name));
        })
    }

    const handleAdd = () => {
        if (fileLabels.some(label => label.Name === newLabelName)) {
          alert('This label name already exists!')
          return
        }

        axios.post(dvSrv + '/api/setting/updatelabel', 
          {Name: newLabelName}, {withCredentials: true})
        .then(response => {
              setFileLabels(prevLabels => [...prevLabels, response.data]);
              setNewLabelName('')
          }).catch(error => console.error("Error adding new label: ", error));
    }

    return (
      <>
      <h3>File Label</h3>
        <div>
            <button class="btn btn-outline-secondary btn-sm me-3" onClick={handleAdd}>Add</button>
            <input 
                type="text"
                value={newLabelName}
                onChange={(e) => setNewLabelName(e.target.value)}
                placeholder="New label name"
            />
            <ul>
                {fileLabels.map(label => (
                    <li key={label.ID}>
                        <button class="btn btn-outline-secondary btn-sm me-3 my-2" onClick={() => handleRemove(label.Name)}>Remove</button>
                        {label.Name}
                    </li>
                ))}
            </ul>


        </div>
        {/* <ReactJson src={{fileLabels}} /> */}
        </>
    )
}

export const UserSettings = ({}) => {
  const session = useContext(SessionContext)
  const [settings, setSettings] = useState({})

  useEffect(() => {
    setSettings(session.data.Settings)
  }, [session.data])

  const onValueChange = (id, desciption, variables, value) => {
    let vs = [...settings] // DON'T point to (ie vs = data), MAKE A COPY!!!
    vs = updateField(vs, id, desciption, variables, value)
    setSettings(vs)
  }

  const onSave = (e) => {
    let vs = [...settings] 
    axios.post(dvSrv + `/api/setting/user`,
      vs, {withCredentials: true})
    .then(({data}) => {
      setSettings(data.Settings)
      session.setData(data)
      toast.success("User Settings are saved!")
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}
    })
  }

  return (    
    <>
    <div class="card">
      <h4 class="card-header text-center">User Settings</h4>
   
   {settings && settings.length > 0 && 
      <div class="card-body">
        <SettingFields ss={settings} onValueChange={onValueChange} />
      </div>
   }
      
      <div class="d-grid gap-2 col-2 mx-auto card-body">
        <button onClick={onSave} type="submit" class="btn btn-primary">Save</button>
      </div>

    </div>
    {/* <ReactJson src={{settings}} collapsed="3" /> */}
    </>
  )
}

export const AccountPolicy = ({}) => {
  const session = useContext(SessionContext)
  //const [policy, setPolicy] = useState()
  const [retentionDays, setRetentionDays] = useState(365)
  const [autoDelete, setAutoDelete] = useState(false)
  const [mobileUploadAll, setMobileUploadAll] = useState(false)
  const [mobileLoginRequired, setMobileLoginRequired] = useState(false)
  const [manualDeleteFromDevice, setManualDeleteFromDevice] = useState(false)
  const [manualDownloadFromDevice, setManualDownloadFromDevice] = useState(false)
  const [manualDeleteFromApp, setManualDeleteFromApp] = useState(false)

  useEffect(() => {
    axios.get(dvSrv + "/api/account/policy", {withCredentials: true})
    .then(({data}) => {
      setRetentionDays(data.RetentionDays)
      setAutoDelete(data.AutoDeleteAfterUpload)
      setMobileLoginRequired(data.MobileLoginRequired)
      setMobileUploadAll(data.MobileUploadAll)
      setManualDeleteFromDevice(data.ManualDeleteFromDevice)
      setManualDownloadFromDevice(data.ManualDownloadFromDevice)
      setManualDeleteFromApp(data.ManualDeleteFromApp)
    })
    .catch(error => {
      if (error.response?.status === 401) {
        setRetentionDays(365)
        setAutoDelete(false)
        setMobileLoginRequired(false)
        setMobileUploadAll(false)
        setManualDeleteFromDevice(false)
        setManualDownloadFromDevice(false)
        setManualDeleteFromApp(false)
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
  }, [])
/*
  const onDeletePolicyChange = (id, desciption, variables, value) => {
    if (value === 1){
      if (!window.confirm("This policy setting will automatically delete original files from the device after they are downloaded to the mobile app. Click OK to confirm!"))
        return
    }
    setAutoDelete(value === 1)
  }
  const onMobileLoginPolicyChange = (id, desciption, variables, value) => {
    setMobileLoginRequired(value === 1)
  }
  const onMobileUploadPolicyChange = (id, desciption, variables, value) => {
    setMobileUploadAll(value === 1)
  }
*/

  const onManualDeviceDeletePolicyChange = (id, desciption, variables, value) => {
    if (value === 1){
      if (!window.confirm("This policy setting will allow the mobile app user to delete original files from the device. PLEASE USE CAUTION! Click OK to confirm."))
        return
    }
    setManualDeleteFromDevice(value === 1)
  }
  const onManualDownloadPolicyChange = (id, desciption, variables, value) => {
    setManualDownloadFromDevice(value === 1)
  }
  const onManualAppDeletePolicyChange = (id, desciption, variables, value) => {
    if (value === 1){
      if (!window.confirm("This policy setting will allow the mobile app user to delete files within the app. Click OK to confirm!"))
        return
    }
    setManualDeleteFromApp(value === 1)
  }

  const onRetentionChange = (e) => {
    if (e.target.value < 0)
      return
    setRetentionDays(Number.parseInt(e.target.value, 10))
  }

  const onSave = (e) => {
    axios.post(dvSrv + `/api/account/policy`,
      {
        RetentionDays: retentionDays,
        AutoDeleteAfterUpload: autoDelete,
        MobileLoginRequired: mobileLoginRequired,
        MobileUploadAll: mobileUploadAll,
        ManualDeleteFromDevice: manualDeleteFromDevice,
        ManualDownloadFromDevice: manualDownloadFromDevice,
        ManualDeleteFromApp: manualDeleteFromApp
      }, 
      {withCredentials: true})
    .then(({data}) => {
      console.log("Account policy is saved!")
      toast.success("Account policy is saved!")
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}
    })
  }

  const asn = session.data.Account.ShortName
  const useMobileSetting =  asn === "garlandfd" || asn === "MCHD" || asn === "uemedical"
  // if (policy)
  return (    
    <>
    <div class="card mt-3">

        <div class="card-body">
          {useMobileSetting && <h4 class="mt-5 mb-3">Mobile App</h4>}
              {/* <div class="mb-2 row">
                <label for="manualdeletedevice" class="col-sm-3 col-form-label bg-warning text-dark">
                  <FontAwesomeIcon icon={faWarning} /> Allow Mobile App User to Select Files to Delete from Device!</label>
                <div class="col-sm-6">
                  <div class="input-group">
                  <InputField fid={-1} fType="bool" fval={ manualDeleteFromDevice ? 1 : 2}
                    onValueChange={onManualDeviceDeletePolicyChange} />
                  </div>
                </div>
              </div>
              <div class="mb-2 row">
                <label for="manualdeleteapp" class="col-sm-3 col-form-label bg-warning text-dark">
                  <FontAwesomeIcon icon={faWarning} /> Allow Mobile App User to Delete Files in the App!</label>
                <div class="col-sm-6">
                  <div class="input-group">
                  <InputField fid={-1} fType="bool" fval={ manualDeleteFromApp ? 1 : 2}
                    onValueChange={onManualAppDeletePolicyChange} />
                  </div>
                </div>
              </div> */}
              {useMobileSetting && <div class="mb-2 row">
                <label for="manualdownloaddevice" class="col-sm-3 col-form-label text-dark">
                  Allow Mobile App User to Select Files to Download from Device</label>
                <div class="col-sm-6">
                  <div class="input-group">
                  <InputField fid={-1} fType="bool" fval={ manualDownloadFromDevice ? 1 : 2}
                    onValueChange={onManualDownloadPolicyChange} />
                  </div>
                </div>
              </div>}
              {/* <div class="mb-2 row">
                <label for="autodelete" class="col-sm-3 col-form-label bg-warning text-dark">
                  <FontAwesomeIcon icon={faWarning} /> Auto Delete Files From Device After Upload</label>
                <div class="col-sm-6">
                  <div class="input-group">
                  <InputField fid={-1} fType="bool" fval={ autoDelete ? 1 : 2}
                    onValueChange={onDeletePolicyChange} />
                  </div>
                </div>
              </div> 
              <div class="mb-2 row">
                <label for="loginrequired" class="col-sm-3 col-form-label">Login Required</label>
                <div class="col-sm-6">
                  <div class="input-group">
                  <InputField fid={-1} fType="bool" fval={ mobileLoginRequired ? 1 : 2}
                    onValueChange={onMobileLoginPolicyChange} />
                  </div>
                </div>
              </div>
              <div class="mb-2 row">
                <label for="uploadall" class="col-sm-3 col-form-label">Upload All Files</label>
                <div class="col-sm-6">
                  <div class="input-group">
                  <InputField fid={-1} fType="bool" fval={ mobileUploadAll ? 1 : 2}
                    onValueChange={onMobileUploadPolicyChange} />
                  </div>
                </div>
              </div> */}

          <h4 class="mt-5 mb-3">Storage</h4>
              <div class="mb-2 row">
                <label for="retentiondays" class="col-sm-3 col-form-label">Retention for Videos (days)</label>
                <div class="col-sm-2">
                  <div class="input-group">
                  <input type="number" class="col-sm-2 form-control form-control-sm mb-1" value={retentionDays} onChange={onRetentionChange} />
                  </div>
                </div>
              </div>

          {/* <div class="form-group row">
            <div class="col-sm-6">
                <label class="fw-bold col-form-label">Retention for Uploaded Videos (days)</label>
            </div>
            <div class="col-sm-6">
                <input type="number" class="form-control form-control-sm mb-1" value={retentionDays} onChange={onRetentionChange} />
            </div>
          </div> */}
        
        </div>
      
      <div class="d-grid gap-2 col-2 mx-auto card-body">
        <button onClick={onSave} type="submit" class="btn btn-primary">Save</button>
      </div>

    </div>
    {/* <ReactJson src={{settings}} collapsed="3" /> */}
    </>
  )
}

export const AccountSettings = ({}) => {
  const session = useContext(SessionContext)
  const [settings, setSettings] = useState({})

  useEffect(() => {
    axios.get(dvSrv + `/api/setting/account`, {withCredentials: true})
    .then(({data}) => {
      setSettings(data)
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
          session.setData(null)
      } else {console.error("Error: ", error)}
    })
  }, [])

  const onValueChange = (id, desciption, variables, value) => {
    let vs = [...settings] // DON'T point to (ie vs = data), MAKE A COPY!!!
    vs = updateField(vs, id, desciption, variables, value)
    setSettings(vs)
  }  

  const onSave = (e) => {
    let vs = [...settings] 
    axios.post(dvSrv + `/api/setting/account`,
      vs, {withCredentials: true})
    .then(({data}) => {
      setSettings(data)
      toast.success("Account Settings are saved!")
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}
    })
  }

  return (    
    <>
    <div class="card mt-3">
      {/* <h4 class="card-header text-center">Account Settings</h4> */}
   
      {settings && settings.length > 0 && 
        <div class="card-body">
          <SettingFields ss={settings} onValueChange={onValueChange}/>
        </div>
      }
      
      <div class="d-grid gap-2 col-2 mx-auto card-body">
        <button onClick={onSave} type="submit" class="btn btn-primary">Save</button>
      </div>

    </div>
    {/* <ReactJson src={{settings}} collapsed="3" /> */}
    </>
  )
}

export const GroupField = ({parent, children, onValueChange}) => {
  console.log("group: ", parent)
  console.log("children: ", children)
  if (parent.FieldType === "group" && children && children.length >0)
  return (
    <>
    <hr />
    <div class="row mb-1">
      <label class="fw-bold form-label col-12">{parent.Description}</label>
      <div class="row" style={{'margin-left': '10px'}}>
        {children.map((field) => 
          <SingleField field={field} onValueChange={onValueChange} />
        )}
      </div>
    </div>
    {/* <ReactJson src={{parent}} collapsed={3} /> */}
    <hr />
    </>
  )
}

export const SingleField = ({ field, onValueChange }) => {
  if (field && field.Enabled)
  return(
    <>
      {field.FieldType!=="bool" && field.FieldValues && field.FieldValues.split(',').length > 0 ? (
        <ValueSelector fid={field.ID} description={field.Description}
                        values={field.FieldValues.split(',')} val={field.Value} 
                        onValueChange={onValueChange} />
      ) : (
        <InputField fid={field.ID} fType={field.FieldType} 
                    description={field.Description}
                    fval={field.Value}
                    onValueChange={onValueChange} />
      )}
    </>
  )
}

export const ValueSelector = ({fid, description, values, val, onValueChange}) => {

  const handleSelect = (e) => {
    onValueChange(fid, description, null, parseInt(e.target.value)) 
  }

  if (values)
  return (
    <div class="form-group row">
    <div class="col-sm-6">
        <label class="fw-bold col-form-label">{description}</label>
    </div>
    <div class="col-sm-6">
      <select class="form-select"
        value={val}
        onChange={handleSelect}>
          {/* <option selected>Pick a</option> */}
          {values.map((val, i) => <option value={i+1}>{val}</option>)}
      </select>
    </div>
  </div>


  )
}

export const InputField = ({fid, fType, description, fval, onValueChange}) => {

  let inType = "text"
  if ( fType === "int" || fType === "float"
      || fType === "double" || fType === "unit") {
    inType = 'number'
  } else if (fType === 'password' || fType === 'file'){
    inType =  fType
  } else if (fType === 'bool'){
    inType = 'checkbox'
  }
  
  const onChange = (e) => {
    if (inType === "file"){
      onValueChange(fid, description, null, e.target.files)
      //e.target.value = null
    } else if (inType === 'checkbox'){
      onValueChange(fid, description, null, e.target.checked ? 1 : 2)
    } else {
      onValueChange(fid, description, null, e.target.value)      
    }
  }

  if (fType !== "group")
  return (
    <>
    <div class="form-group row">
      <div class="col-sm-6">
        {inType === 'file' && fval
          ? <div>
              <label class="fw-bold col-form-label">{description}</label>
              <img src={fval} alt="" width="32" height="32" class="rounded-circle me-2" />
            </div>
          : <div><label class="fw-bold col-form-label">{description}</label></div>
        }
      </div>
      <div class="col-sm-6">
        {inType === 'file' 
          ? <input type={inType} class="form-control form-control-sm mb-1" accept=".svg" onChange={onChange} />
          : inType === 'checkbox'
            ? <input type={inType} class="form-check-input mb-1" checked={fval===1} onChange={onChange} />
            : <input type={inType} class="form-control form-control-sm mb-1" value={fval} onChange={onChange} />
        }
      </div>
    </div>
    {/* <ReactJson src={{field}} collapsed={1} /> */}
    </>
  )
}

const SettingFields =({ss, onValueChange}) =>{

  var groupFields = {}
  useEffect(() => {
    getGroupFields()
  }, [ss])

  const getGroupFields = () =>{
    if(ss && ss.length > 0) {
      ss.filter(v=>v.Enabled && v.FieldType==="group").map(field =>{
        groupFields[field.FieldName]=[]
        for (const fn of field.FieldValues.split(',')){
          groupFields[field.FieldName].push(ss.filter(f=>f.FieldName === fn)[0])
        }
      })
    }
    // console.log("in getGroupFields: ", groupFields)
  }

  const isGroupChild = (name) =>{
    if (Object.keys(groupFields).length === 0) {
      getGroupFields()
    }

    for (const [parent, children] of Object.entries(groupFields)){
      for (const field of children){
        if(field.FieldName === name){
          // console.log("fieldname found: ", name)
          return true          
        }
      }
    }
    // console.log("fieldname not found: ", name)
    return false
  }

  if(ss && ss.length > 0) 
  return(
  <>
    <div>
      {ss.filter(v=>v.Enabled && v.FieldType !== "group" 
      && !isGroupChild(v.FieldName)).map(field => 
        <SingleField field={field} onValueChange={onValueChange} />)
      }
    </div>

    <div>
      {groupFields && Object.keys(groupFields).length > 0 && 
        ss.filter(v=>v.Enabled && v.FieldType === "group").map(field => 
        <GroupField parent={field} children={groupFields[field.FieldName]} onValueChange={onValueChange} />)
      }
    </div>
    {/* <ReactJson src={{groupFields}} collapsed={3} /> */}
  </>
  )
}

const updateField = (vs, id, desciption, variables, value) =>{
  let index = vs.findIndex(v => v.ID === id)
  if (index === -1) {
    return vs // vs.push({FieldDescription: desciption, FieldValueList: variables, Value: value}) // new data
  } else {
    vs[index].FieldDescription = desciption
    vs[index].FieldValueList = variables
    if (vs[index].FieldType === "file"){
      getFileString(value[0], vs[index]) // value is "e.target.files"
      console.log('******** file vs[index].Value:', vs[index].Value);
    } else {
      vs[index].Value =  value
    }
  
    return vs
  }
}

const getFileString = async (file, field) => {
  // console.log("****** logo file: ", file)
  // const reader = new FileReader()
  // reader.readAsDataURL(file)
  // if(reader.readyState === 2) {// Done
  //   console.log("****** reading logo result: ", reader.result)
  //   //const strBase64 = reader.result.split(',')[1]
  //   return reader.result
  // } else {
  //   console.log("****** reading logo error: ", reader.err)
  //   return ""
  // }

  try {
    field.Value = await readFileAsText(file)
    // Now you can call other functions or perform additional tasks with the logoText
    //console.log('******** reading file result:', field.Value);
  } catch (error) {
    console.error('Error:', error);
  }
}

const readFileAsText = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const strResult = event.target.result; // Get the text content
      resolve(strResult)//.split(',')[1]);//reader.result.split(',')[1]
    };

    reader.onerror = (event) => {
      // Reject the promise if an error occurs
      reject(event.target.error);
    };

    reader.readAsDataURL(file); // Read the selected file as text
  })

}
