import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { dvSrv } from "../App"
import { Link } from "react-router-dom"
import { SessionContext } from "../App"
import { TeamSelector } from "./Team"
import { GetUserFullname, UserNotifyModal, SearchableUserSelector } from "./User"
import { PaginationTable } from "./Pagination"
import ReactJson from '@microlink/react-json-view';

import DateTimePicker from "react-datetime-picker"
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { useTeamAndUser, useDevices, useReports } from "../hook/DataProvider"
import { TimeStringForTimeZone, CurrentTimeZoneName, CurrentTimeFormat } from "./Setting"
import moment from "moment-timezone"
import { GetDeviceSNWithTag, IsSerialValid } from "./Device"
import { ReportStatus } from "./Report"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFlag } from "@fortawesome/free-solid-svg-icons"
import { toUrlSafeBase64 } from "./utils"

export const VideoThumbnail = ({video}) => {
  return (
    <Link to={`/case/${toUrlSafeBase64(video.CaseNumber)}`}>
      <div className="thumbnail-container">
        <img src={`data:image/png;base64,${video.thumbnailBase64}`} alt={video.FileName} />
        <span class="duration">{video.Duration}</span>
        <h3>{video.FileName}</h3>
      </div>
    </Link>
  )
}

export const VideoTableInstruction = () => {
  return (
    <div class="mt-3">
      <span>
        <FontAwesomeIcon icon={faFlag} size="sm" color="red"/>&nbsp;&nbsp;No report&nbsp;&nbsp; 
        <FontAwesomeIcon icon={faFlag} size="sm" color="orange"/>&nbsp;&nbsp;Report in progress&nbsp;&nbsp;
        <FontAwesomeIcon icon={faFlag} size="sm" color="green"/>&nbsp;&nbsp;Report done
      </span>
    </div>
  )
}


export const VideoTab = ({item, teams, users, devices, reports, refresh}) => {
  const [video, setVideo] = useState(item)
  const [createTZTime, setCreateTZTime] = useState()
  const [edit, setEdit] = useState(false)
  const session = useContext(SessionContext)
  const [showNotifyModal, setShowNotifyModal] = useState(false)

  // useEffect(() => {
  //   setCurrTZ(CurrentTimeZoneName(session))
  // }, [])

  useEffect(() => {
    setVideo(item)//{...data, ["CaseNumber"]: item.CaseNumber, ["TeamID"]: item.TeamID, ["ProviderID"]: item.ProviderID})
    setCreateDateWithZone(item.CreateDate)
  }, [item])

  const setCreateDateWithZone = (date) =>{
    // console.log("CurrentTimeZoneName(session): ", CurrentTimeZoneName(session))
    setCreateTZTime(moment(date).tz(CurrentTimeZoneName(session)).format(CurrentTimeFormat))
  }
  const handleDateTime = (date) => {
    // assign the date-time from picker directly,
    // otherwise the picker clock time may not be the same with input
    // due to time zone conversion.
    // The DateTimePicker seems ALWAYS to use local time.
    setCreateTZTime(date)
    //setData({...data, ["CreateDate"]: date})
  }
  const handleCNChange = (e) => setVideo({...video, ["CaseNumber"]: e.target.value})
  const handleSNChange = (e) => setVideo({...video, ["Source"]: e.target.value})
  
  const trimmedCaseNum = video.CaseNumber !== null ? video.CaseNumber.trim() : video.CaseNumber
  const trimmedSerialNum = video.Source !== null ? video.Source.trim() : video.Source

  const handleAssign = (e) => {
    if (item.CaseNumber && item.CaseNumber !== "" && (trimmedCaseNum === null || trimmedCaseNum === "")){
      alert("The Case # can not be empty!")
      return
    }
    // if there was a Device for the video, it can't be removed
    if (item.Source && item.Source !== "" && (trimmedSerialNum === null || trimmedSerialNum === "")){
      alert("The Device Serial# can not be empty!")
      return
    // adding a device for a video
    } else if (trimmedSerialNum !== null && trimmedSerialNum !== "" && trimmedSerialNum !== item.Source){
      if (!IsSerialValid(trimmedSerialNum, devices)){
        alert("The Device Serial# must be listed inside Devices table. Please add new device to the Devices table first!")
        return
      }
    }

    // console.log("moment(createTZTime): ", moment(createTZTime))
    // console.log("moment(createTZTime).format: ", moment(createTZTime).format("L hh:mm a"))
    let ctz = moment.tz( moment(createTZTime).format(CurrentTimeFormat), 
      'MM/DD/YYYY h:mm a', (CurrentTimeZoneName(session)))
    // console.log("ctz: ", ctz)
    
    axios.post(dvSrv + `/api/video/${item.ID}`, {
      ID: item.ID,
      CreateDate: ctz,
      CaseNumber: trimmedCaseNum,
      Source: trimmedSerialNum,
      ProviderID: video.ProviderID,
      TeamID: video.TeamID,
    }, {withCredentials: true})
    .then(({data}) => {
      setEdit(false)
      video.CaseNumber = trimmedCaseNum
      refresh()
    })
    .catch(error => { console.error("Error: ", error) } )
  }

  const handleCancel = (e) => {
    setVideo(item)
    setCreateDateWithZone(item.CreateDate)
    setEdit(false)
  } 
  
  const handleDelete = (e) => {
    if (window.confirm("Are you sure you want to delete this video? Only admin or video provider can do this!")){
      axios.delete(dvSrv + `/api/video/${item.ID}`, {withCredentials: true})
      .then(({data}) => refresh())
      .catch(error => { console.error("Error: ", error) } )      
    }
  }
  const onEdit = (e) => {
    setEdit(!edit)
  }
  const providerFullName = GetUserFullname(video.ProviderID, users)
  const team = video.TeamID && teams && teams.find((item) => item.ID === video.TeamID)

  // console.log("Reports:", reports)
  const getReportStatus = (cn) =>{
    // console.log("CN: ", cn)
    let frs = reports?.filter(r=>r.CaseNumber === cn && r.Attempt === 0)
    if (frs?.length > 0){
      return frs[0].Status!=="" ? frs[0].Status : ReportStatus.InProgress
    }

    return ""
  }

  // Columns: Case #, video time, upload time, file name, device/tag, provider, team, action
  const getFlagColor = (cn) =>{
    let rStatus = getReportStatus(cn)
    switch (rStatus){
      case ReportStatus.Done:
        return "green"
      case ReportStatus.InProgress:
        return "orange"
    }
    return "red"
  } 

  if (video)
  return (
    <>
    <tr>
      <td id="case-number-with-flag">
        <FontAwesomeIcon icon={faFlag} size="xs" color={getFlagColor(video.CaseNumber)}/>&nbsp;&nbsp;
        {edit
        ? <input type="text" class="form-control-sm" value={video.CaseNumber} placeholder="CaseNumber" aria-label="casenumber" onChange={handleCNChange}/>
        : <Link to={`/case/${toUrlSafeBase64(video.CaseNumber)}`}>{video.CaseNumber}</Link>
        }
      </td>
      <td>
        {edit
        ? <DateTimePicker 
            onChange={handleDateTime} 
            value={createTZTime} 
          />
        : TimeStringForTimeZone(session, video.CreateDate)
        }
      </td>
      <td>{TimeStringForTimeZone(session, video.CreatedAt)}</td>
      <td>{video.FileName}</td>
      <td>
      {edit
        ? <input type="text" class="form-control-sm" value={video.Source} placeholder="Device Serial#" aria-label="serial#" onChange={handleSNChange}/>
        : GetDeviceSNWithTag(video.Source, devices)
      }
      </td>
      <td>
        {edit 
        ? <SearchableUserSelector users={users} data={video} setData={setVideo} />
        : providerFullName !== "" ? <span>{providerFullName}</span> : null
        }
      </td>
      <td>
        {(edit && video.ProviderID === 0) 
        ? <TeamSelector teams={teams} data={video} setData={setVideo} />
        : <span>{team ? team.Name : null}</span>
        }
      </td>
      <td>
        {edit
        ? <div class="btn-group" role="group">
            <button class="btn btn-sm btn-success py-0" type="button" onClick={handleAssign}>Assign</button>
            <button class="btn btn-sm btn-warning py-0" type="button" onClick={handleCancel}>Cancel</button>
          </div>
        : <div class="btn-group" role="group">
            <button class="btn btn-outline-secondary btn-sm py-0" onClick={onEdit}>Edit</button>
            {video.CaseNumber && session?.data?.IsRoot && <button class="btn btn-outline-secondary btn-sm py-0" onClick={()=>setShowNotifyModal(true)}>Notify</button>}
            {session?.data?.IsRoot && <button class="btn btn-outline-secondary btn-sm py-0" onClick={handleDelete} disabled={edit}>Delete</button>}
          </div>
        }
      </td>
    </tr>
    { users && <UserNotifyModal show={showNotifyModal} setShow={setShowNotifyModal} caseNum={item.CaseNumber} />}
    {/* <ReactJson src={{deviceTag, video, device}} /> */}
    {/* <Tooltip anchorSelect="#case-number-with-flag" place="top">Red flag, no report; green flag, has report</Tooltip> */}
    </>
  )
}

export const MyVideos = () => {
  const [flag, setFlag] = useState(false)
  const refresh = () => setFlag(!flag)
  const [apiUrl, setApiUrl] = useState("") 

  useEffect(() => {
    setApiUrl('/api/video/self')
  }, [flag])

  return (
    <>
     <VideoTableWithPagination apiUrl={apiUrl} refresh={refresh} repaging={flag}/>
    {/* <Uploader refresh={refresh} setUploading={setUploading} /> */}
    {/* <VideoAssignTable videos={data.Videos} users={users} teams={teams} handleDelete={refresh}/>
    <hr /> */}
    {/* <VideoTable refresh={refresh} videos={data} users={users} teams={teams} /> */}
    {/* <ReactJson src={data} collapsed="1" /> */}
    </>
  )
}

const _columns = [
  { key: 'case_number', label: 'Case #', jfield:'CaseNumber', sortable: true, searchable: true },
  { key: 'create_date', label: 'Video Time', jfield:'CreateDate', sortable: true, searchable: true },
  { key: 'created_at', label: 'Upload Time', jfield:'CreatedAt', sortable: true, searchable: true },
  { key: 'file_name', label: 'File Name', jfield:'FileName', sortable: true, searchable: false  },
  { key: 'source', label: 'Device | Tag', jfield:'Source', sortable: true, searchable: true },
  { key: 'provider_id', label: 'Assigned To', jfield:'ProviderID', sortable: true, searchable: true },
  { key: 'team_id', label: 'Team', jfield:'TeamID', sortable: true, searchable: false },
  { key: 'action', label: 'Action', jfield:'NA', sortable: false, searchable: false },
  // Add more columns as needed
]

export const VideoTableWithPagination = ({apiUrl, refresh, repaging}) => {
  const [teams, users] = useTeamAndUser()
  const devices = useDevices()
  const reports = useReports()

  const itemProps = {
    teams: teams,
    users: users,
    devices: devices,
    reports: reports,
  }
  const instructions = ""
//   if (devices)
  return (
    <PaginationTable apiUrl={apiUrl} columns={_columns} sortbycol='create_date' refresh={refresh} 
      repaging={repaging} ItemComponent={VideoTab} itemProps={itemProps} InstructionComponent={VideoTableInstruction}/>
  )
}

export const VideoTable = ({videos, teams, users, refresh}) => {

  if (videos)
  return (
  <>
    <table class="table table-hover">
      <thead>
      {_columns.map((column) => <th key={column.key}>{column.label}</th> )}
        {/* <th scope="col">#</th> */}
        {/* <th>Source</th>
        <th>Filename</th>
        <th>Video Time</th>
        <th>Upload Time</th>
        <th>Case #</th>
        <th>Provider</th>
        <th>Team</th>
        <th></th> */}
        {/* <th>Last Updated</th> */}
      </thead>
      <tbody>
        {videos.sort((a,b) => {
            const aCN = a.CaseNumber.toUpperCase()
            const bCN = b.CaseNumber.toUpperCase()
            if (aCN < bCN) return -1
            if (aCN > bCN) return 1
            return 0
          }).map(video => <VideoTab item={video} teams={teams} users={users} refresh={refresh} />)}
      </tbody>
    </table>
    <VideoTableInstruction />
  </>
  )
}

export const VideoSimpleTable = ({videos}) => {
  const session = useContext(SessionContext)
  if (videos)
  return (
    <table class="table table-hover">
    <thead>
      <th scope="col">#</th>
      <th>Source</th>
      <th>Filename</th>
      <th>Video Time</th>
      <th>Upload Time</th>
      <th>Last Updated</th>
    </thead>
    <tbody>
      {videos.map(video => 
    <>
      <tr>
        <th scope="row"></th>
        <td>{video.Source}</td>
        <td><Link to={`/case/${toUrlSafeBase64(video.CaseNumber)}`}>{video.FileName}</Link></td>
        <td>{TimeStringForTimeZone(session, video.CreateDate)}</td>
        <td>{TimeStringForTimeZone(session, video.CreatedAt)}</td>
        <td>{TimeStringForTimeZone(session, video.UpdateAt)}</td>
      </tr>
    </>)}
    </tbody>
  </table>
  )
}
