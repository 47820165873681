import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { dvSrv } from "../App"
import { Link, useParams } from "react-router-dom"
import ReactJson from "@microlink/react-json-view"
import { SessionContext } from "../App"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faTrashCan, faUser, faSortAsc, faSortAlphaAsc } from "@fortawesome/free-solid-svg-icons"
import { faCopy, faEyeSlash } from "@fortawesome/free-regular-svg-icons"
import { toast } from "react-toastify"
import { Tooltip } from "react-tooltip"
import { useTeamAndUser, useTeamAndAllUsers } from "../hook/DataProvider"
import { MFASetupModal } from "../MFA"
import { TimeStringForTimeZone } from "./Setting"
import { getEmailPrefix } from "./utils"
import { Button, Modal } from "react-bootstrap"
import DateTimePicker from "react-datetime-picker"
import Select, { components } from 'react-select'
import { toUrlSafeBase64 } from "./utils"

export const Users = () => {
  // const [teams, users] = useTeamAndUser()
  return (
    <>
    <h2>Users</h2>
    <UserTable />
    {/* <ReactJson src={{users}} /> */}
    </>
  )
}

export const UserTable = () => {
  const session = useContext(SessionContext)
  const [showDisabled, setShowDisabled] = useState(false)

  const [flag, setFlag] = useState(false)
  const refresh = () => {
    setFlag(!flag)
  }

  const [teams, users] = useTeamAndUser([flag])
  
  const sortedUsers = sortUsersByLastName(users)

  // Search functionalities
  const [searchResults, setSearchResults] = useState([])
  const [searchInput, setSearchInput] = useState("")
  
  const isUserFound = (user, val, disabled) =>{
    return user.Disabled === disabled && (user.FirstName + " " + user.LastName + " " + getEmailPrefix(user.username)).toLowerCase().includes(val.toLowerCase())
  }
  const handleSearchChange = (val) =>{
    const trimmedInput = val.trim()
    setSearchInput(trimmedInput)
    
    // Check if the trimmed input is empty
    if (trimmedInput === "") {
        setSearchResults(sortedUsers.filter(user=>user.Disabled === showDisabled))
        return
    }
    setSearchResults(sortedUsers.filter(user => isUserFound(user, trimmedInput, showDisabled)))
  }

  const onShowChange = (e) => {
    setShowDisabled(!showDisabled)
    setSearchInput("")
    setSearchResults(sortedUsers.filter(user=>user.Disabled === !showDisabled))
    //refresh()
  }
  // when user status change, Enable <=> Disable or Deleted, it will not be displayed within current Active/Inactive selection
  const onStatusChange =(id)=>{
    setSearchResults(searchInput==="" ? sortedUsers.filter(user=>user.ID !== id && user.Disabled === showDisabled) 
    : setSearchResults(sortedUsers.filter(user => user.ID !== id && isUserFound(user, searchInput, showDisabled))))
  }
  
  if (sortedUsers)
  return (
    <>
    <div class="row">
      <div class="col-4">
        <div class="btn-group me-3 col-12">
          <input type="radio" class="btn-check" id="showActiveUser"
            value={1} checked={!showDisabled} onChange={onShowChange}/>
          <label class={`btn btn-outline-secondary`}
            for="showActiveUser">Show Active</label>

          <input type="radio" class="btn-check" id="showInActiveUser"
            value={2} checked={showDisabled} onChange={onShowChange} />
          <label class={`btn btn-outline-secondary`}
            for="showInActiveUser">Show Inactive</label>
        </div>
      </div>

      <div class="col-4">
        <input type="text" class="form-control mb-3 mx-2 me-2" style={{ border: '1px solid blue' }} id="search-by-name" 
        value={searchInput}
        placeholder="Search by Name ..." 
        onChange={(e) => handleSearchChange(e.target.value)}/>
      </div>
    </div>
    {/* <div class="form-check text-start my-3">
          <input class="form-check-input" type="checkbox" id="flexCheckShowDisabled" value={showDisabled} onChange={()=>setShowDisabled(!showDisabled)} />
          <label class="form-check-label me-2" for="flexCheckShowDisabled">
            Show Disabled User
          </label>
        </div> */}
    <table class="table table-hover">
      <thead>
        <th style={{ backgroundColor: 'lightgrey'}}>Last Name<FontAwesomeIcon size='sm' style={{ backgroundColor: 'lightgrey', marginRight: '1px', marginLeft: '1px', marginTop :'1px'}} icon={faSortAsc}/></th>
        <th>First Name</th>
        <th>Username</th>
        <th>Team</th>
        {/* <th class="text-center">Root</th> */}
        <th class="text-center">TeamAdmin</th>
        {/* <th class="text-center">IP Restriction</th> */}
        <th class="text-center">Mobile Access</th>
        <th class="text-center">Mobile Read</th>
        <th class="text-center">Mobile Edit</th>
        <th class="text-center">Web Access</th>
        <th class="text-center">SSO Only</th>
        <th class="text-center">Edit Report</th>
        <th class="text-center">Edit Note</th>
        <th class="text-center">Edit Video</th>
        <th class="text-center">Last Sign-in</th>
        <th class="text-center">Status</th>
        <th class="text-center">Action</th>
      </thead>
      <tbody>
        {(searchInput !== "" || searchResults.length > 0) 
        ? searchResults.map(
            user => <UserTab user={user} teams={teams} refresh={refresh} onStatusChange={onStatusChange} />)
        : sortedUsers.filter(user=>user.Disabled === showDisabled).map(
            user => <UserTab user={user} teams={teams} refresh={refresh} onStatusChange={onStatusChange}/>)}
      </tbody>
    </table>
    </>
  )
}

export const UserTab = ({user, teams, refresh, onStatusChange}) => {
  const session = useContext(SessionContext)
  const [data, setData] = useState(user)
  const [edit, setEdit] = useState(false)
  
  useEffect(() => {
    setData(user) // Update data when user prop changes
  }, [user])

  const handleEdit = (e) => {
    if (edit) { // canceling, reset team and username
      data.TeamID = user.TeamID
      data.username = user.username
    }
    setEdit(!edit)
  }

  const handleSelect = (e) => {
    setData({...data, ["TeamID"]: parseInt(e.target.value)})
  }
  const onUsernameChange = (e) => {
    setData({...data, ["username"]: e.target.value})
  }
  
  const handleErrorResponse = (error) => {
    if (error?.response?.status === 401) {
      session.setData(null)
    } else {
      console.error("Error: ", error)
      toast.error(error?.response?.data?.error)
    }
  }

  const handleAssign = () => {
    const trimmedUN = data.username.trim()
    if(trimmedUN === ""){
      alert("The Username cannot be empty or just white spaces.")
      return
    }

    axios.post(dvSrv + `/api/user/${data.ID}`, {
      ID: data.ID,
      TeamID: data.TeamID,
      Username: data.username === user.username ? user.username : trimmedUN
    }, {withCredentials: true})
    .then(({data}) => {
      setData(data)
      setEdit(false)
      if (refresh) refresh()
    })
    .catch(error => {
      if (error?.response?.status === 409) { // StatusConflict
        toast.error(`The username, ${data.username}, already exists. Please use email as username!`)
      } else {handleErrorResponse(error)}})   
  }

  const handleDisable = () => {
    axios.put(dvSrv + `/api/user/${data.ID}`, {
      ID: data.ID,
      Disabled: !data.Disabled,
    }, {withCredentials: true})
    .then(({data}) => {
      setData(data)
      if(onStatusChange) onStatusChange(data.ID)
      if (refresh) refresh()
    })
    .catch(error => handleErrorResponse(error))
  }

  const handleDelete = () => {
    let delUser = data.username
    let delID = data.ID
    if (window.confirm(`Are you sure you want to delete this user, ${delUser}?`)){
      axios.delete(dvSrv + `/api/user/${data.ID}`, {withCredentials: true})
      .then(({data}) => {
        if (onStatusChange) onStatusChange(delID)
        if (refresh) refresh()
        toast.success(`The user, ${delUser}, has been deleted.`)
      })
      .catch(error => {
        if (error?.response?.status === 409) { // StatusConflict
          toast.error(`The user, ${delUser}, can not be deleted. The user may have been assigned with videos or reports!`)
        } else {handleErrorResponse(error)}})      
    }

  }

  const handleValueChange = (field, val) => {
    axios.put(dvSrv + `/api/user/${data.ID}`, {
      ID: data.ID,
      [field]: val,
    }, {withCredentials: true})
    .then(({data}) => {
      setData(data)
      if (refresh) refresh()
    })
    .catch(error => handleErrorResponse(error))
  }

  if (data)
  return (
    <>
    <tr>
      <td>{data.LastName}</td>
      <td>{data.FirstName}</td>
      {!edit 
        ? <td>{data.username}</td>
        : <td class="text-center">
          <div class="input-group">
            <input type="email" class="form-control" name="floatingUsername" 
              placeholder="name@example.com" value={data.username} onChange={onUsernameChange} />
            {/* <button class="btn btn-sm btn-warning" type="button" onClick={handleAssign}>Save</button> */}
          </div>
          </td>
      }
      
      {!edit
      ? data.TeamID 
        ? <td>{teams.find(t => t.ID === data.TeamID).Name}</td>
        : <td class="text-decoration-line-through">Not assigned</td>
      : <td>
          <div class="input-group">
            <div class="input-group-text">
            Team
            </div>
            <select class="form-select form-select-sm" aria-label=".form-select-sm"
              name="teamAssigned"
              value={data.TeamID || ""}
              onChange={handleSelect}>
                <option selected>Pick a team</option>
                {teams.map(team => <option value={team.ID}>{team.Name}</option>)}
            </select>
            <button class="btn btn-sm btn-warning" type="button" onClick={handleAssign}>Save</button>
          </div>
        </td>
      }
      {/* <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.IsRoot}  
        onChange={(e) => handleValueChange("IsRoot", e.target.checked)} disabled={data.ID === session.data.ID}/></td> */}
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.IsAdmin}  
        onChange={(e) => handleValueChange("IsAdmin", e.target.checked)} disabled={data.IsRoot}/></td>
      {/* <td class="text-center">{data.IPAccess}</td> */}
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.MobileAccess} 
        onChange={(e) => handleValueChange("MobileAccess", e.target.checked)} disabled={data.ID === session.data.ID || data.SsoOnly}/></td>
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.MobileRead} 
        onChange={(e) => handleValueChange("MobileRead", e.target.checked)} disabled={!data.MobileAccess || data.SsoOnly}/></td>
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.MobileEdit} 
        onChange={(e) => handleValueChange("MobileEdit", e.target.checked)} disabled={!data.MobileAccess || data.SsoOnly}/></td>
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.WebAccess}  
        onChange={(e) => handleValueChange("WebAccess", e.target.checked)} disabled={data.ID === session.data.ID || data.SsoOnly}/></td>
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.SsoOnly}  
        onChange={(e) => handleValueChange("SsoOnly", e.target.checked)} disabled={data.ID === session.data.ID}/></td>
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.ReportEdit}  
        onChange={(e) => handleValueChange("ReportEdit", e.target.checked)}/></td>
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.NoteEdit}  
        onChange={(e) => handleValueChange("NoteEdit", e.target.checked)}/></td>
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.VideoEdit}  
        onChange={(e) => handleValueChange("VideoEdit", e.target.checked)}/></td>
      <td class="text-center">{data.LastSignedIn && TimeStringForTimeZone(session, data.LastSignedIn)}</td>
      <td class="text-center">{data.Disabled ? <span class="text-decoration-line-through">Disabled</span> : "Active"}</td>
      <td class="text-center">
        <button class="btn btn-sm btn-outline-secondary" onClick={handleEdit} disabled={data.ID === session.data.ID}>{edit ? "Cancel" : "Edit"}</button>
        <button class="btn btn-sm btn-outline-secondary" onClick={handleDisable} disabled={data.ID === session.data.ID}>{data.Disabled ? "Enable" : "Disable"}</button>
        <ResetUserPasswordButton user={data} />
        <button class="btn btn-sm btn-outline-secondary" onClick={handleDelete} disabled={data.ID === session.data.ID}><FontAwesomeIcon icon={faTrashCan} /></button>
      </td>
    </tr>
    {/* <ReactJson src={{data}} /> */}
    </>
  )
}

// This should only be used by root user, and the api call is limited to root.
export const ResetUserPasswordButton = ({user}) => {
  const session = useContext(SessionContext)
  const handleResetPassword = () =>{
    const npass = window.prompt("Set New Password for User: " + user.username)
    if (npass !== null && (npass === "" || npass.length < 6)){
      alert("The password has to be at least 6 characters long!")
      return
    }
    if (npass){ // in case prompt Cancelled, npass will be null
      axios.post(dvSrv + `/api/user/${user.ID}/resetpassword`, {
        NewPassword: npass,
      }, {withCredentials: true})
      .then(({data}) => {
        //setData(data)
      })
      .catch(error => {if (error.reponse && error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}})
    } 
  }

  if (user){
  return (
    <button class="btn btn-sm btn-outline-secondary" onClick={handleResetPassword}>Reset Password</button>
  )}
}

// export const UserCard = ({user}) => {
//   return (
//   <div class="card mb-3" style={{maxWidth: "450px"}}>
//     <div class="row g-0">
//       <div class="col-md-3">
//         <img src="http://placehold.it/200x320" class="img-fluid rounded-start" alt="..." />
//       </div>
//       <div class="col-md-9">
//         <div class="card-body">
//           <Link to={`/user/${user.ID}`}>
//           <h5 class="card-title">{user.FirstName} {user.LastName}</h5>
//           </Link>
//           <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
//           <p class="card-text"><small class="text-muted">Last sign in: {TimeStringForTimeZone(session, user.LastSignin)}</small></p>
//         </div>
//       </div>
//     </div>
//   </div>
//   )
// }

export const User = () => {
  const session = useContext(SessionContext)

  const {id} = useParams()
  const [user, setUser] = useState()

  useEffect(() => {
  axios.get(dvSrv + `/api/user/${id}`, {withCredentials: true})
  .then(({data}) => setUser(data))
  .catch(error => {
    if (error.response.status === 401) {
      session.setData(null)
    } else { console.error("Error: ", error) }
  })}, [])

  if (user) {
  return (
  <>
    <div>
      <h1>{user.Name}</h1>
      <h2>User Profile</h2>
        {user.username}
    </div>
    {/* <ReactJson src={{user}} collapsed="1" /> */}
  </>
  )}
}

export const UserTabSimple = ({user, level, resetPWD}) => {
  return (
    <tr>
      <td colSpan={5} style={{textIndent: `60px`}}>
        <FontAwesomeIcon icon={faUser} /> &nbsp;
        {user.FirstName} {user.LastName} | {user.username}
      </td>
      {resetPWD && <td colSpan={5}>
        <ResetUserPasswordButton user={user} />
      </td>}
     
    </tr>
  )
}

export const UserProfile = () => {
  const session = useContext(SessionContext)
  const [data, setData] = useState()
  const [mfaEnabled, setMfaEnabled] = useState(false)
  const [showMfaModal, setShowMfaModal] = useState(false)

  useEffect(() => {
    axios.get(dvSrv + "/api/user/self", {withCredentials: true})
    .then(({data}) => {
      setData(data)
      setMfaEnabled(data.MFAEnabled)
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
  }, [])

  const onNameChange = (e) => {
    const {name, value} = e.target
    setData({...data, [name]: value})
  }

  const onNameSave = () => {
    axios.put(dvSrv + `/api/user/self`, data, {withCredentials: true})
    .then(({data}) => {
      setData(data)
      toast.success("Name has been changed successfully.")
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else {
        console.error("Error: ", error)
        toast.error(error.response.data.error)
      }
    })
  }

  // const handleMFAResult = (success)=>{
  //     setMfaEnabled(success)
  // }
  const editMFA = ()=>{setShowMfaModal(true)}
  const handleMFAEnabled = (enabled) =>{

    axios.post(dvSrv + `/api/mfa/enable`, {
      MFAEnabled: enabled,
    }, {withCredentials: true})
    .then(({data}) => {
//      setData(data)
      setMfaEnabled(enabled)
      setShowMfaModal(enabled)
      // toast.success("MFA status changed.")
    })
    .catch(error => {
      if (error.response.status === 401) {
        setMfaEnabled(!enabled)
        session.setData(null)
      } else {
        console.error("Error: ", error)
        toast.error(error.response.data.error)
      }
    })
  }

  if(data)
  return (
    <>
    <h2>Your Profile</h2>

    <div class="my-2 row">
      <label for="staticEmail" class="col-sm-2 col-form-label">Username</label>
      <div class="col-sm-6">
        <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={data.username} />
      </div>
    </div>
    <div class="mb-2 row">
      <label for="team" class="col-sm-2 col-form-label">Team</label>
      <div class="col-sm-6">
        <input type="text" readonly class="form-control-plaintext" id="team" value={data.Team.Name} />
      </div>
    </div>
    <div class="mb-2 row">
      <label for="last" class="col-sm-2 col-form-label">Last Signed-in</label>
      <div class="col-sm-6">
        <input type="text" readonly class="form-control-plaintext" id="last" value={TimeStringForTimeZone(session, data.LastSignedIn)} />
      </div>
    </div>

    <h4 class="mt-5 mb-3">Change Name</h4>
    <div class="mb-2 row">
      <label for="firstname" class="col-sm-2 col-form-label">First Name</label>
      <div class="col-sm-6">
        <div class="input-group">
        <input type="text" class="form-control" name="FirstName" id="firstname" value={data.FirstName} onChange={onNameChange} />
        <button type="submit" class="btn btn-outline-secondary" onClick={onNameSave}>Submit</button>
        </div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="lastname" class="col-sm-2 col-form-label">Last Name</label>
      <div class="col-sm-6">
        <div class="input-group">
        <input type="text" class="form-control" name="LastName" id="lastname" value={data.LastName} onChange={onNameChange} />
        <button type="submit" class="btn btn-outline-secondary" onClick={onNameSave}>Submit</button>
        </div>
      </div>
    </div>

    <h4 class="mt-5 mb-3">Change Password</h4>
    {data && data.NumSignedIn === 1 && 
    <span class="badge rounded-pill bg-warning fs-6 text-dark mb-2">This is the first time you signed in, please RESET your initial password!</span>}
    <PasswordChanger username={data.username} />

    <h4 class="mt-5 mb-3">MFA</h4>
    <div class="form-check text-start my-3">
      <input class="form-check-input" type="checkbox" id="flexCheckDefault" checked={mfaEnabled} onChange={()=>handleMFAEnabled(!mfaEnabled)} />
      <label class="form-check-label me-2" for="flexCheckDefault">Enable MFA</label>
      {mfaEnabled && <button id="setupmfa" class="btn btn-sm btn-outline-secondary" onClick={editMFA}><FontAwesomeIcon icon={faEdit} /></button>}
      {mfaEnabled && !data.MFASetup && <span class="mx-2 bg-warning"> &nbsp;&nbsp;Please finish setting up MFA!&nbsp;&nbsp; </span>}
    </div>
    <MFASetupModal user={data} show={showMfaModal} setShow={setShowMfaModal} />

    <Tooltip anchorSelect="#show-password" place="top">Show password</Tooltip>
    <Tooltip anchorSelect="#copyPassword" place="top">Copy password to clipboard</Tooltip>
    <Tooltip anchorSelect="#setupmfa" place="top">Config MFA</Tooltip>

    {/* <ReactJson src={{mfaEnabled, data}} /> */}
  </>
  )
}

export const PasswordChanger = ({username}) => {
  const session = useContext(SessionContext)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  
  const validPassword = 
    newPassword === newPassword2 
    && newPassword.length > 5

  const randomPassword = () => {
    let password = GeneratePassword(8);
    setNewPassword(password);
    setNewPassword2(password)
  }

  const copyPassword = () => {
    if (newPassword) {
      navigator.clipboard.writeText(newPassword);
      toast.success("Password copied to the clipboard.")
      // setShowModal(true);
      // setTimeout(() => {
      //   setShowModal(false);
      // }, 3000);
    }
  }

  const changePassword = () => {
    axios.post(dvSrv + `/api/changepassword`, {
      Username: username,
      Password: password,
      NewPassword: newPassword,
    }, {withCredentials: true})
    .then(({data}) => {
      toast.success("Password has been reset successfully.")
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else {
        console.error("Error: ", error)
        toast.error(error.response.data.error)
      }
    })
  }

  return (
    <>
    <div class="mb-2 row">
      <label for="currentPassword" class="col-sm-2 col-form-label">Current Password</label>
      <div class="col-sm-6">
        <input type={showPassword ? "text" : "password"} class="form-control" placeholder="Please enter your current password (required)" id="currentPassword" value={password} onChange={e => setPassword(e.target.value)} />
      </div>
    </div>
    <div class="mb-2 row">
      <label for="inputPassword" class="col-sm-2 col-form-label">New Password</label>
      <div class="col-sm-6">
        <div class="input-group">
          <input type={showPassword ? "text" : "password"} class="form-control" placeholder="Please enter your new password" id="inputPassword" value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
          <button class="btn btn-outline-secondary" type="button" id="show-password" onClick={()=>setShowPassword(!showPassword)}>
            {showPassword 
            ? <FontAwesomeIcon icon={faEyeSlash} />
            : <FontAwesomeIcon icon={faEye} />
          }
          </button>
          <button class="btn btn-outline-secondary" onClick={randomPassword} type="button">Auto-Generate</button>
          <button class="btn btn-outline-secondary" onClick={copyPassword} type="button" id="copyPassword" disabled={!newPassword2}><FontAwesomeIcon icon={faCopy} /> Copy</button>
          </div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="inputPassword2" class="col-sm-2 col-form-label">Repeat New Password</label>
      <div class="col-sm-6">
        <div class="input-group">
          <input type={showPassword ? "text" : "password"} class="form-control" placeholder="Please repeat your new password" id="inputPassword2" value={newPassword2} onChange={e => setNewPassword2(e.target.value)}/>
          <button type="submit" class="btn btn-outline-secondary" onClick={changePassword} disabled={!password || !validPassword}>Submit</button>
        </div>
      {newPassword !== newPassword2 && "password do not match"}
      </div>
    </div>
    </>
  )
}

export const GeneratePassword = (length) => {
  const useUppercase = 1 //document.getElementById("uppercase").checked;
  const useLowercase = 1 //document.getElementById("lowercase").checked;
  const useNumbers = 1 //document.getElementById("numbers").checked;
  const useSymbols = 1 //document.getElementById("symbols").checked;

  let charset = "";
  if (useUppercase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (useLowercase) charset += "abcdefghijklmnopqrstuvwxyz";
  if (useNumbers) charset += "0123456789";
  if (useSymbols) charset += "!@#$%^&*";

  let password = "";
  for (let i = 0; i < length; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return password
}

export const UserNotifyModal = ({show, setShow, caseNum}) => {
  
  const [teams, users] = useTeamAndAllUsers()
  // State to keep track of checked rows
  const [checkedState, setCheckedState] = useState([])
  const [emailBody, setEmailBody] = useState('')
  const [dueDate, setDueDate] = useState()

  const sortedUsers = sortUsers(users)

  // Search functionalities
  const [searchResults, setSearchResults] = useState([])
  const isUserFound = (user, val) =>{
    return (user.FirstName + " " + user.LastName).toLowerCase().includes(val.toLowerCase())
  }

  const handleSearchChange = (val) =>{
    const trimmedInput = val.trim();
    // Check if the trimmed input is empty
    if (trimmedInput === "") {
        setSearchResults(sortedUsers)
        return
    }
    setSearchResults(sortedUsers.filter(user => isUserFound(user, val)))
  }

  useEffect(() => {
    if (!show) return
    let checked = new Array(sortedUsers.length).fill(false)
    const encodedId = toUrlSafeBase64(caseNum)
    axios.get(dvSrv + `/api/case/${encodedId}`, {withCredentials: true})
    .then(({data}) => {
      //check videos providers
      sortedUsers.map((user, index) => {
        if (data.Videos){
          for (const v of data.Videos){
            if (v.ProviderID === user.ID){
              checked[index] = true
              break
            }
          }
        }
        // check reports providers
        if (data.Reports && !checked[index]){
          for (const r of data.Reports){
            if (r.ProviderID === user.ID){
              checked[index] = true
              break
            } else if (r.CoProviderIDs?.includes(user.ID)){
              checked[index] = true
              break
            }
          }
        }
      })

      setSearchResults(sortedUsers)
      setCheckedState(checked)
    })
    .catch(error => {
      setCheckedState(checked)
      console.error("Error: ", error)
      toast.error(error.response?.data?.error)
    })
  }, [caseNum, users, show])

  const onHide = () => setShow(false)
  const handleSubmit = () => {
    const selectedUserIDs = sortedUsers
      .filter((user, index) => checkedState[index]) // Filter selected users
      .map(user => user.ID) // Extract the ID of each selected user
//      .join(','); // Join IDs into a string

    if (selectedUserIDs.length === 0) {
      alert('No users selected!')
      return
    }
    axios.post(dvSrv + `/api/task/notify`,
      {
      Cases: new Array(1).fill(caseNum),
      UserIDs: selectedUserIDs,
      EmailBody: emailBody,
      DueDate: dueDate,
      },
      {withCredentials: true})
      .then(({}) => {
        toast.success("Notifications sent successfully")
      })
      .catch((error) => {
        toast.error(error.response?.data?.error)
      })
    setShow(false)
  }
  const handleDateTime = (date) => {
    // assign the date-time from picker directly,
    // otherwise the picker clock time may not be the same with input
    // due to time zone conversion.
    // The DateTimePicker seems ALWAYS to use local time.
    setDueDate(date)
  }
  return (
    <Modal show={show} onHide={onHide} centered={true}>
      <div className="modal-content">
      <Modal.Header closeButton>
        <Modal.Title>Select users to notify on case #{caseNum}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '60vh', overflowY: 'auto' }}>
        <div class="row col-12">
            <input type="text" class="form-control mb-3 mx-2" style={{ border: '1px solid blue' }} id="search-by-name" placeholder="Search by Name ..." 
            onChange={(e) => handleSearchChange(e.target.value)}/>
        </div>
        <UserSelectorTable users={sortedUsers} shownUsers={searchResults} teams={teams} 
        checkedState={checkedState} setCheckedState={setCheckedState}/>
        <textarea class="form-control mb-3" placeholder="Email Message" 
            style={{ height: '75px' }}
            onChange={e => setEmailBody(e.target.value)}
            id="notifyEmailBody" >
        </textarea>
        <div class="row">
          <div class="col-4"><label for="taskDueDate">Due Date</label></div>
          <div class="col-8"><DateTimePicker id="taskDueDate" onChange={handleDateTime} value={dueDate}/></div>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>Notify</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </Modal.Footer>
      </div>
    </Modal>
  )
}

export const UserSelectorTable = ({users, shownUsers, teams, checkedState, setCheckedState}) => {

    // Handler for changing the header checkbox
    const handleHeaderCheckboxChange = (event) => {
      setCheckedState(new Array(users.length).fill(event.target.checked));
    };
  
    // Handler for changing individual row checkboxes
    const handleRowCheckboxChange = (position) => {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
      );
  
      setCheckedState(updatedCheckedState);
    };  
  
  return (
    <>
    <table class="table table-hover">
      <thead>
        <th style={{ backgroundColor: 'lightgrey'}}>Username</th>
        <th>Full Name</th>
        <th>Team</th>
        <th class="text-center">
          <input
              type="checkbox"
              onChange={handleHeaderCheckboxChange}
              checked={checkedState.length>0 && checkedState.every(Boolean)}
            />
        </th>
      </thead>
      <tbody>
        {users && users.map((user, index) => (users.length===shownUsers.length || shownUsers.some((selU)=>user.username===selU.username))
          && <UserSelectorTab user={user} index={index} checked={checkedState[index]} 
          teams={teams} handleRowCheckboxChange={handleRowCheckboxChange}/>
        )}
      </tbody>
    </table>
    {/* <ReactJson src={{checkedState}} /> */}
    </>
  )
}

export const UserSelectorTab  = ({user, index, checked, teams, handleRowCheckboxChange}) => {
  return(
    <tr>
      <td>{user.username}</td>
      <td>{user.FirstName} {user.LastName}</td>
      {user.TeamID 
        ? <td>{teams.find(t => t.ID === user.TeamID).Name}</td>
        : <td class="text-decoration-line-through">Not assigned</td>}
      <td>
        <input
          type="checkbox"
          checked={checked}
          onChange={() => handleRowCheckboxChange(index)}
        />
      </td>
    </tr>
  )
}

const customSearchInput = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      {/* <FontAwesomeIcon icon={faSearch} /> */}
      <components.Input {...props} placeholder=" Search ..."/>
    </div>
  )
}

export const SearchableUserSelector = ({users, data, setData, multi=false}) => {
  const sortedUsers = sortUsers(users)
  // console.log(" data: ", data)
  const options = userOptions(sortedUsers)
  
  const [selProvider, setSelProvider] = useState(null)
  const [selUsers, setSelUsers] = useState([])
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    let inUser = sortedUsers?.find(item => item.ID === data.ProviderID)
    setSelProvider(inUser ? {value: inUser.username, label: inUser.FirstName+" "+inUser.LastName} : null)
  
    let selPros = []
    let selProIds = data.CoProviderIDs ? data.CoProviderIDs : []
    // console.log("selProIds: ", selProIds)
    if (sortedUsers && selProIds.length > 0){
      // console.log("### selProIds: ", selProIds)
      sortedUsers.map(item => {
        if (selProIds.includes(item.ID))
          selPros.push({value: item.username, label: item.FirstName+" "+item.LastName})
      })}
    
      setSelUsers(selPros)
  }, [data, users])

  const handleSelect = (selected, action) => {
    
    setSearchInput("")
    if (multi){
      setSelUsers(selected)
      // console.log("Users selected: ", selected)
      let copids = []
      if (sortedUsers && selected.length > 0){
        selected.map(item => {
          for (const u of sortedUsers){
            if(item.value === u.username){
              copids.push(u.ID)
              break
          }}   
        })}
      // console.log("### copids: ", copids)
      setData({...data, ["CoProviderIDs"]: copids})
      return
    } 

    setSelProvider(selected)
    let selUser = sortedUsers.find(item => item.username === selected?.value)

    if (selUser){
      if (selUser.TeamID)
        setData({...data, ["ProviderID"]: selUser.ID, ["TeamID"]: selUser.TeamID})
      else
        setData({...data, ["ProviderID"]: selUser.ID})
    }
  }

  // const Input = props => <components.Input {...props, isHidden: false } />

  // const handleInputChange = (value, action) => {
  //   console.log('Input change triggered!');
  //   console.log('Value:', value); // Log the input value
  //   console.log('Action:', action); // Log the action object
  //   if (action.action === 'input-change') {
  //     setSearchInput(value) // Keep the input text updated
  //   }
  //   console.log("searchInput: ", searchInput)
  // }

  if (sortedUsers)
  return (
    <Select className={multi ? "basic-multi-select" : "basic-single"}
      classNamePrefix="select"
      // defaultValue={selUser?.username}
      value={multi ? selUsers : selProvider}
          // : selUser ? {value: selUser.username, label: selUser.FirstName +" " +selUser.LastName} : ''}
      onChange={handleSelect}
      options={options}
      placeholder=""//{multi ? "Pick Co-Providers" : "Pick a provider"}
      isMulti={multi}
      isSearchable
      components={{ Input: customSearchInput }}
      inputValue={searchInput}
      onInputChange={(value, action) => {
        if (action.action === 'input-change') {
          setSearchInput(value); // Update state
          // console.log("searchInput: ", searchInput)
        }
      }}
      styles={{
        valueContainer: (provided) => ({
          ...provided,
          display: 'inline-flex',
        }),
        input: (provided) => ({
          ...provided,
          display: 'inline-flex', // Change from grid to flex
          border: '1px solid blue',
        }),
      }}
      />
  )
}

export const UserSelector = ({users, data, setData}) => {
  const sortedUsers = sortUsers(users)
  const handleSelect = (e) => {
    let user = sortedUsers.find(item => item.ID === parseInt(e.target.value))

    if (user && user.TeamID)
      setData({...data, ["ProviderID"]: parseInt(e.target.value), ["TeamID"]: user.TeamID})
    else
      setData({...data, ["ProviderID"]: parseInt(e.target.value)})
  }

  if (sortedUsers)
  return (
    <select class="form-select" name="userAssigned" style={{ maxHeight: '10vh', overflowY: 'auto', display: 'block' }}
      value={data.ProviderID || ""}
      onChange={handleSelect}>
        <option disabled selected>Pick a provider</option>
        {sortedUsers.map(item => <option value={item.ID}>{item.username} | {item.FirstName} {item.LastName}</option>)}
    </select>
  )
}

// Common convenient function
export const GetUserFullname = (uid, users) => {
    const user = uid && users && users.find((item) => item.ID === uid)
    return user ? user.FirstName + " " + user.LastName : ""
}

const sortUsers = (users) =>{
  if (!users) return null
  return [...users].sort((a,b)=> {
    if (a.username < b.username) return -1
    if (a.username > b.username) return 1
    return 0
  })
}

const sortUsersByLastName = (users) =>{
  if (!users) return null
  return [...users].sort((a,b)=> {
    if (a.LastName?.toLowerCase() < b.LastName?.toLowerCase()) return -1
    if (a.LastName?.toLowerCase() > b.LastName?.toLowerCase()) return 1
    return 0
  })
}

const userOptions = (users) =>{
  let options = []
  users?.map(item => {
    options.push({value: item.username, label: item.FirstName + " " + item.LastName})
  })
  return options
}
