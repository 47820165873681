import React, {FunctionComponent, useState, useEffect} from 'react';
import {
    Label,
    Legend,
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ReferenceArea,
    ResponsiveContainer,
  } from 'recharts';

  const LINE_COLORS = [
    '#0088FE', // Blue
    '#00C49F', // Teal
    '#FFBB28', // Amber
    '#FF8042', // Orange
    '#8884D8', // Purple
    '#82CA9D', // Light Green
    '#FF6666', // Soft Red
    '#A28EFF', // Lavender
    '#00B2FF', // Sky Blue
    '#FF6699', // Pink
    '#B0DE33', // Lime Green
    '#FF9933', // Deep Orange
    '#33CCCC', // Cyan
    '#9966CC', // Violet
    '#339933'  // Forest Green
  ];

export const ProviderReportChart = ({field, reports, userVals, users}) => {
    const [state, setState] = useState({});
    const {
        data,
        // left,
        // right,
        // refAreaLeft,
        // refAreaRight,
        top,
        bottom,
        // top2,
        // bottom2,
    } = state;

    const [dataMin, setDataMin] = useState()
    const [dataMax, setDataMax] = useState()

    const [userData, setUserData] = useState([])

    const sortUserValsByFullName = (userVals) =>{
      if (!userVals) return null
      return [...userVals].sort((a,b)=> {
        if (a.fullname?.toLowerCase() < b.fullname?.toLowerCase()) return -1
        if (a.fullname?.toLowerCase() > b.fullname?.toLowerCase()) return 1
        return 0
      })
    }

    useEffect(() => {

      if (field.FieldType === "text") return

      let tmpMin = undefined
      let tmpMax = undefined
      let fdUserVals = []
    //   console.log("userVals: ", userVals)
      for (let uid in userVals) {
        let user = users.find(u=>u.ID === parseInt(uid)) // uid may be "None"
        // console.log("uid, user: ", uid, user)
        let name = uid==="None" ? uid : user.LastName + ", " + user.FirstName
        let vals = userVals[uid]
        // console.log("user vals: ", vals)
        
        if (vals === null || Object.keys(vals).length === 0) continue

        if (field.FieldType === 'number'){ // key is "val_sum"
            let total = userVals[uid]["count"]===undefined ? 1 : userVals[uid]["count"]
            let val = parseFloat(vals["val_sum"]/total).toFixed(2)
            tmpMin = tmpMin===undefined ? val : (Math.min(val, tmpMin))
            tmpMax = tmpMax===undefined ? val : (Math.max(val, tmpMax))
            fdUserVals.push({fullname: name,
                             average: val})
        }else{ // bool, bool+
            let uData = {}
            uData["fullname"] = name
            // console.log("field.FieldValues: ", field.FieldValues)
            for (const fv of field.FieldValues?.split(',')){
                // console.log("vals[fv]: ", vals[fv])
                if (vals[fv] === undefined) continue
                let val = parseFloat(vals[fv]/reports.length*100).toFixed(1)
                tmpMin = tmpMin===undefined ? val : (Math.min(val, tmpMin))
                tmpMax = tmpMax===undefined ? val : (Math.max(val, tmpMax))
                uData[fv] = val
                // console.log("uData[fv]: ", uData[fv])
            }
            fdUserVals.push(uData)
        }
      }

      setDataMax(tmpMax)
      setDataMin(tmpMin)
    //   console.log("fdUserVals: ", fdUserVals)

      if (fdUserVals.length > 0){
        let sortedData = sortUserValsByFullName(fdUserVals)
        // console.log("fdUserVals: ", fdUserVals)
        // console.log("sortedData: ", sortedData)
        setUserData(sortedData)

        //   console.log("sorted user Data: ", sortedData)
        // console.log("dataMin, dataMax: ")
        let initialState = {
            data: sortedData,
            // left: "dataMin",
            // right: "dataMax",
            // refAreaLeft: "",
            // refAreaRight: "",
            top: tmpMax+1,
            bottom: tmpMin-1,
            // top2: "dataMax+20",
            // bottom2: "dataMin-20",
            // animation: true,
            };

        setState(initialState)        
      }

      
    }, [userVals, field, reports])

    const getAxisYDomain = (
        from,
        to,
        ref,
        offset
      ) => {
        const refData = userData.slice(from - 1, to);
        let [bottom, top] = [refData[0][ref], refData[0][ref]];
      
        refData.forEach((d) => {
          if (d[ref] > top) top = d[ref];
          if (d[ref] < bottom) bottom = d[ref];
        });
      
        return [(bottom | 0) - offset, (top | 0) + offset];
    };
      
    // const zoom = () => {
    //     if (refAreaLeft === refAreaRight || refAreaRight === "") {
    //         setState((prev) => ({
    //         ...prev,
    //         refAreaLeft: "",
    //         refAreaRight: "",
    //         }));
    //         return;
    //     }

    //     let [refAreaLeftTemp, refAreaRightTemp] = [refAreaLeft, refAreaRight];

    //     // xAxis domain
    //     if (refAreaLeft > refAreaRight)
    //         [refAreaLeftTemp, refAreaRightTemp] = [refAreaRight, refAreaLeft];

    //     // yAxis domain
    //     const [bottom, top] = getAxisYDomain(
    //         refAreaLeftTemp,
    //         refAreaRightTemp,
    //         field.FieldType === 'number' ? "average" : field.FieldValues?.split(',')[0],
    //         1
    //     );
    //     // const [bottom2, top2] = getAxisYDomain(
    //     //     refAreaLeftTemp,
    //     //     refAreaRightTemp,
    //     //     "impression",
    //     //     50
    //     // );

    //     setState((prev) => ({
    //         // refAreaLeft: "",
    //         // refAreaRight: "",
    //         data: prev.data.slice(),
    //         // left: refAreaLeftTemp,
    //         // right: refAreaRightTemp,
    //         // bottom,
    //         // top,
    //         // bottom2,
    //         // top2,
    //     }));
    // };

    const zoomOut = () => {
        setState((prev) => ({
            data: prev.data.slice(),
            // refAreaLeft: "",
            // refAreaRight: "",
            // left: "dataMin",
            // right: "dataMax",
            top: dataMax+1,
            bottom: dataMin-1,
            // top2: "dataMax+50",
            // bottom2: "dataMin+50",
        }));
    };

    const component = (
        <div className="highlight-bar-charts" style={{ userSelect: "none" }}>
            <ResponsiveContainer height={240} width="100%">
            <LineChart
                // width={800}
                // height={400}
                data={data}
                // margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                // onMouseDown={(e) =>
                // setState((prev) => ({ ...prev, refAreaLeft: e.activeLabel }))
                // }
                // onMouseMove={(e) =>
                // state.refAreaLeft &&
                // setState((prev) => ({ ...prev, refAreaRight: e.activeLabel }))
                // }
                // onMouseUp={() => zoom()}
            >
                {/* <Legend width={100} wrapperStyle={{ top: 40, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} /> */}
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                // allowDataOverflow
                dataKey="fullname"
                tick={<CustomizedAxisTick />}
                // domain={[left, right]}
                type="category"
                height={40}
                />
                <YAxis
                allowDataOverflow="false"
                domain={[bottom, top]}
                type="number"
                yAxisId="1"
                />
                {/* <YAxis
                orientation="right"
                allowDataOverflow
                domain={[bottom, top]}
                type="number"
                yAxisId="2"
                /> */}
                <Tooltip />
                <Legend />
                {field.FieldType === 'number' && 
                    <Line
                        yAxisId="1"
                        // type="natural"
                        dataKey="average"
                        stroke={LINE_COLORS[0]}
                        animationDuration={300}
                    /> }
                {field.FieldType !== 'number' && field.FieldValues?.split(',').map((val, index) =>
                    <Line
                        yAxisId="1"
                        // type="natural"
                        dataKey={val}
                        stroke={LINE_COLORS[index%LINE_COLORS.length]}
                        animationDuration={300}
                    />)}
   
                {/* <Line
                yAxisId="2"
                type="natural"
                dataKey="impression"
                stroke="#82ca9d"
                animationDuration={300}
                /> */}

                {/* {refAreaLeft && refAreaRight ? (
                <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                />
                ) : null} */}
            </LineChart>
            </ResponsiveContainer>
        </div>
    );
    if (field)
    return (
        <React.Fragment>
            {/* <button type="button" className="btn update" onClick={() => zoomOut()}>
            Zoom Out
            </button> */}
            {component}
        </React.Fragment>
    );
}

const CustomizedAxisTick = (props={}) => {
    const { x, y, payload } = props;
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor="end"
          fontSize={10}
          fill="#666"
          transform="rotate(-45)"
        >
          {payload.value}
        </text>
      </g>
    );
  };