import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import ReactJson from "@microlink/react-json-view"
import { dvSrv } from "../App"
import { SessionContext } from '../App';
import { SearchableUserSelector } from './User';
import moment from 'moment-timezone';
import { useActiveTemplate, useTeamAndUser } from '../hook/DataProvider';
import { TimeStringForTimeZone } from "./Setting"
import { toast } from "react-toastify"
import { ReportImage } from './ReportImage';
import { GetUserFullname } from "./User";
import { initFieldMap, toUrlSafeBase64 } from './utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCheck, faFlag } from "@fortawesome/free-solid-svg-icons"
import { Tooltip } from 'react-tooltip'
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { ProviderReportChart } from './Chart';

export const ReportStatus = {
  InProgress: "reportInprogress",
  Done: "reportDone",
}

export const Reports = () => {
  const session = useContext(SessionContext)
  const [reports, setReports] = useState([])
  const [fieldReps, setFieldReps] = useState([]) 
  const [attReps, setAttReps] = useState([]) 
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [flag, setFlag] = useState(false)
  const [teams, users] = useTeamAndUser()
  const [showByProvider, setShowByProvider] = useState(false)

  useEffect(() => {
    axios.get(dvSrv + "/api/report/", {params: {
      startDate,
      endDate,
    }, withCredentials: true})
    .then(({data}) => {
      setReports(data)
      let tmpFieldReps = []
      let tmpAttReps = []
      for(const report of data){
        // console.log("report: ", report)
        if (/*report.Attempt === 0*/report.TemplateType === "Field_Report"){
          tmpFieldReps.push(report)
        } else if(/*report.Attempt > 0*/report.TemplateType === "Attempt_Report"){
          tmpAttReps.push(report)
        }
      }
      
      setFieldReps(tmpFieldReps)
      setAttReps(tmpAttReps)
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
  }, [flag])

  const handleDateChange = (setter) => (event) => {
    setter(event.target.value)
  }
  const applyTimeFilter = (start, end) => {
    setFlag(!flag)
  }
  const clearTimeFilter = () =>{
    setStartDate('')
    setEndDate('')
    setFlag(!flag)
  }

  return (
    <>
    {/* <ReactJson src={fieldReps} collapsed={3} /> */}
    <div>
      <div class="row mb-3">
        <div class='col-5'>
          <label class="fw-bold">
            Videos From:&nbsp;
            <input class="me-3" type="date" value={startDate} onChange={handleDateChange(setStartDate)} />
          </label>
          <label  class="fw-bold">
            To:&nbsp;
            <input class="me-3" type="date" value={endDate} onChange={handleDateChange(setEndDate)} />
          </label>
          <div class="btn-group" role="group">
              <button class="btn btn-sm btn-success py-0" type="button" onClick={applyTimeFilter}>Apply</button>
              <button class="btn btn-sm btn-warning py-0" type="button" onClick={clearTimeFilter}>Clear</button>
          </div>
        </div>
        <div class="col-3 form-check text-start">
              <input class="form-check-input" type="checkbox" id="flexCheckshowbyprovider" value={showByProvider} onChange={()=>setShowByProvider(!showByProvider)} />
              <label class="form-check-label me-2" for="flexCheckshowbyprovider">
                Show plots by provider
              </label>
        </div>
      </div>
      <ul class="nav nav-tabs" id="reportsTabs" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active internal-tab" id="field-reports-tab" data-bs-toggle="tab" data-bs-target="#fieldreports" type="button" 
          role="tab" aria-controls="fieldreports" aria-selected="false">Field Reports</button>
        </li>
        { /*(data.IsAdmin || data.IsRoot) &&*/ <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="att-reports-tab" data-bs-toggle="tab" data-bs-target="#attreports" type="button" 
          role="tab" aria-controls="attreports" aria-selected="false">Attempt Reports</button>
        </li>}

      </ul>
      <div class="tab-content" id="reportsContents">
        <div class="tab-pane fade show active" id="fieldreports" role="tabpanel" aria-labelledby="field-reports-tab">
          <ReportPage tname="ReportTemplate" data={fieldReps} users={users} showByProvider={showByProvider}/>
        </div>
        <div class="tab-pane fade" id="attreports" role="tabpanel" aria-labelledby="att-reports-tab">
          <ReportPage tname="AttemptTemplate" data={attReps} users={users} showByProvider={showByProvider}/>
        </div>
      </div>
    </div>
    {/* <ReactJson src={users} collapsed={1} /> */}
    </>
  )

}

export const ReportPage = ({tname, data, users, showByProvider}) =>{
  const template = useActiveTemplate(tname)
  const [fieldUserVals, setFieldUserVals] = useState({})

  useEffect(() => {
    if (!template || !template.Fields || !users ||!showByProvider) return

    let fdUserVals = {}
    for (const field of template.Fields) {
      if (field.FieldType === 'text') continue

      let fvs = field.FieldValues?.split(',')
      let userVals = {}
      for (const report of data) {
        let user = users.find(u=>u.ID === report.ProviderID)
        let uid = user === undefined ? "None" : user.ID

        if (userVals[uid] == undefined) userVals[uid]={}

        if (field.FieldType != 'number'){
          if (!fvs || fvs === undefined || fvs.length == 0){
            continue
          }else{
            for (const fv of fvs) {
              if (userVals[uid][fv] === undefined) userVals[uid][fv]=0
            }
          }
        } else if (userVals[uid]["val_sum"] === undefined){
          userVals[uid]["val_sum"] = 0
        }
        
        for (const v of report.FieldValues) {
          if(field.ID === v.FieldID){
            
            if (field.FieldType === 'number'){              
              userVals[uid]["val_sum"] += v.Value
              userVals[uid]["count"] = userVals[uid]["count"]===undefined ? 1 : userVals[uid]["count"]+1
            } else { // bool, bool+
              let dvs = v.FieldValueList.split(',')
              if (v.Value > 0 && dvs.length>=v.Value){
                let key = dvs[v.Value-1]
                if (fvs.includes(key)){
                  userVals[uid][key] += 1              
                }
              }
            }
            break           
          }
        }
      }

      fdUserVals[field.ID] = userVals
    }

    setFieldUserVals(fdUserVals)
    
  }, [data, tname, showByProvider])

  let total = data.length
  const average = (id) => {
    let sum = 0

    data.forEach(report => {
        for (const v of report.FieldValues) {
            // console.log("field value array, v.Value, val ", dvs, v.Value, val)
            if (v.FieldID === id){              
                sum += v.Value
                break
            }        
        }
    })
    // console.log("sum, total ", sum, total)
    return parseFloat(sum/total).toFixed(2)
  }

  const percent = (id, val) => {
    let sum = 0

    data.forEach(report => {
        for (const v of report.FieldValues) {
            // console.log("field value array, v.Value, val ", dvs, v.Value, val)
            if (v.FieldID === id){
              let dvs = v.FieldValueList.split(',')
              if (v.Value > 0 && dvs[v.Value-1]===val){
                sum += 1
                break
              }
            }        
        }
    })
    // console.log("sum, total ", sum, total)
    if (sum > 0) return parseFloat(sum/total*100).toFixed(1)/*+"%"*/
  }

  if (data && template)
  return (
    <>
    {/* <h2>{isAttempt ? "Attempt" : "Field"} Reports</h2> */}
    <table class="table table-hover">
      <thead>
        <th>#</th>
        <th class="text-center"></th>
        <th>Description</th>
        {/* <th>Options</th> */}
        <th class="text-center">% of {total} reports</th>
        {showByProvider && <th class="text-center">By provider</th>}
      </thead>
      <tbody>
        {/* {data && data.map(report => <ReportTab report={report} />)} */}

        {template.Fields?.map(field => 
        <tr>
          <td>{field.Number}</td>
          <td class="text-center">
            <input class="form-check-input" type="checkbox"
              checked={field.Enabled} />
          </td>
          <td>{field.Description}</td>
          {/* <td>{field.FieldValues?.split(',').map((val, id) => <p>{val}: {percent(field.ID, id)}</p>)}</td> */}
          <td width={200} class="text-center">
            {field.FieldType === 'text' ? "N/A" 
             : field.FieldType === 'number' ? "average " + average(field.ID) : <ResponsiveContainer width="100%" height={240}>
              <BarChart data={field.FieldValues?.split(',').map((val) => ({cat: percent(field.ID,  val), name: val}))}
                margin={{ top: 20, right: 10, left: 10, bottom: 5 }} maxBarSize={30}>
                <XAxis dataKey="name" />
                <YAxis /*domain={[0, 'auto']}*/ allowDataOverflow={false} hide={true}/> 
                <Bar dataKey="cat" fill='#8884d8' isAnimationActive={false}>
                  <LabelList dataKey="cat" position="top" offset="1"/>
                </Bar>
              </BarChart>
            </ResponsiveContainer>}
          </td>
          {showByProvider && <td width="%100" class="text-center">
            {field.FieldType === 'text' ? "N/A" 
             : <ProviderReportChart field={field} reports={data} userVals={fieldUserVals[field.ID]} users={users}/>}
          </td>}
        </tr>)}

      </tbody>
    </table>
    {/* <ReactJson src={{template, data}} /> */}
    </>
  )
}

export const ReportTab = ({report}) => {
  const session = useContext(SessionContext)

  if (report && report.FieldValues)
  return (
    <>
    <tr>
      <td></td>
      <td>{report.CaseNumber}</td>
      <td>{TimeStringForTimeZone(session, report.Date)}</td>
      <td>{report.TeamID}</td>
      {report.FieldValues.map(v => 
        <td>
          {v.Value}
        </td>
      )}
    </tr>
    </>
  )
}

export const RadioButtonGroup = ({title, name, value, onValueChange, labels=["Yes","No","N/A"], colors=["secondary"]}) => {

  return (
    <div class="row mb-3">
      <label class="fw-bold form-label">{title}</label>
      <div class="btn-group col-12">
        {labels.map((label, i) => 
          <>
            <input type="radio" class="btn-check" name={name} id={`${name}-${i+1}`}
              value={i+1} checked={value === i+1} onChange={onValueChange} />
            <label class={`btn btn-outline-${colors.length === 1 ? colors[0] : colors[i]}`}
              for={`${name}-${i+1}`}>{label}</label>
          </>
        )}
      </div>
    </div>
  )
}

export const RadioButtonGroupForField = ({attempt, field, fvalues, fields, onValueChange, relations}) => {
  const baseName = `att_${attempt}_${field.FieldName}`
  
  let data = fvalues?.find(v => v.FieldID === field.ID)
  // use the original value to figure out the index in the field.FieldValues because it may have
  // changed from the template
  // console.log("field: ", field )
  // console.log("data: ", data)
  let oldV = ""
  let index = -1
  if (field?.FieldValues !== data?.FieldValueList && data?.Value>0) {
    let fvs = field.FieldValues.split(',')
    let dvs = data.FieldValueList.split(',')
    index = fvs.indexOf(dvs[data.Value-1])
    // if old FieldValue is found in new template FieldValues, 
    // let's update the old FieldValueList
    if (index>=0) {
      onValueChange(field.ID, field.Description, field.FieldValues, index+1)
    } else{ // the old value is not in new template FieldValues anymore
      oldV = dvs[data.Value-1]
    }
  } else if (data){ index = data.Value-1}

  let val = index >=0 ? index+1 : 0
  // console.log("oldV val: ", oldV, val)
  const [show, setShow] = useState(true)

  useEffect(() => {
    let ignore = false
    // manage relations
    if (!ignore && relations?.length > 0){
      handleRelations(field, relations, fields, fvalues, onValueChange, null, setShow)
    }
    return () => {
      ignore = true
    }
  }, [field, fvalues])

  if(field && show)
  return (
<>
    <RadioButtonGroupPlus id={field.ID} name={baseName} fromProvider={field.FromProvider}
                      type={field.FieldType} description={field.Description}
                      vals={field.FieldValues} val={val} oldV={oldV}
                      onValueChange={onValueChange} />
{/* <ReactJson src={{field, data}} collapsed={1} /> */}
</>
)

}

export const RadioButtonGroupPlus = ({id, name, fromProvider, type, description, vals, val, oldV, onValueChange}) => {

  const onChange = (e) => {
    let v = parseInt(e.target.value, 10)
    onValueChange(id, description, labels.join(','), v)
  }

  let labels, colors = ["secondary"]
  
  switch (type) {
    case 'bool':
      labels = ["Yes","No"]
      colors = ["success", "danger"]
      break      
    case 'bool+':
      labels = ["Yes","No", "N/A"]
      colors = ["success", "danger", "secondary"]
      break
    case 'string':
    case 'int':
    default:
      labels = vals.split(',')
      break
  }

  return (
    <>
    <div class="row mb-3">
    {fromProvider 
    ? <label class="fw-bold form-label"><FontAwesomeIcon icon={faUserCheck} id="icon-provider-input"/>&nbsp;&nbsp;{description}&nbsp;&nbsp;{oldV==="" ? <span /> : <span class="bg-warning">(last saved value: {oldV})</span>}</label>
    : <label class="fw-bold form-label">{description}&nbsp;&nbsp;{oldV==="" ? <span /> : <span class="bg-warning">(last saved value: {oldV})</span>}</label>}
    
      <div class="btn-group col-12">
        {labels.map((label, i) => 
          <>
          <input 
            type="radio" 
            class="btn-check" 
            name={name} 
            id={`${name}-${i+1}`}
            value={i+1}
            checked={val === i+1}
            onChange={onChange} 
          />
          <label class={`btn btn-outline-${colors.length === 1 ? colors[0] : colors[i]}`}
            for={`${name}-${i+1}`}>{label}</label>
          </>
        )}
      </div>
    </div>
    {/* <ReactJson src={{field}} collapsed={1} /> */}
    <Tooltip anchorSelect="#icon-provider-input" place="right">Check with the provider</Tooltip>
    </>
  )
}

const getFieldValue=(fv, fields)=>{
  let vals = fv.FieldValueList?.split(',')
  let field = fields?.find(f=>f.ID === fv.FieldID)
  if (!field) return { fVal: '', fField: null };

  return { fVal: (field.FieldType==='number' || field.FieldType==='text' ? fv.Value
    : (fv.Value > 0 ? vals[fv.Value-1] : '')), fField: field }
}

const getInputType = (field)=>{
  return field?.FieldType==='number' || field?.FieldType==='text' ? field?.FieldType : ''
}

const handleRelations =(field, relations, fields, fvalues, onValueChange, setFdRange, setShow) =>{
  const inputType = getInputType(field)
  const dvs = field.FieldValues?.split(',')
  for (let i = 0; i < relations.length; i++) {
    let relation = relations[i]
    // console.log("relation: ", relation)
    
    if (relation.TargetFieldIDs.includes(field.ID)){
      let triggerFV = fvalues?.find(v=>v.FieldID === relation.TriggerFieldID)
      
      if (!triggerFV) continue

      // let targetFV = fvalues?.find(v=>v.FieldID === field.ID)
      // console.log("triggerFV: ", triggerFV)
      let condMet = false
      const { fVal, fField } = getFieldValue(triggerFV, fields)
      const isNumber = fField?.FieldType==='number'
      switch (relation.TriggerCondition){
        case "=": 
          condMet = relation.TriggerValue === fVal; break
        case ">=":
          condMet = isNumber && fVal >= relation.TriggerValue; break
        case "<=":
          condMet = isNumber && fVal <= relation.TriggerValue; break
        case "<":
          condMet = isNumber && fVal < relation.TriggerValue; break
        case ">":
          condMet = isNumber && fVal > relation.TriggerValue; break
      }
      // console.log("conditionMet, relation, field, inputType ", condMet, relation, field, inputType)
      if (condMet){
        switch (relation.Action){
          case "setValue":
            onValueChange(field.ID, field.Description, field.FieldValues, 
              inputType==='number' ? parseFloat(relation.Parameters["value"]) 
              : (inputType==='text' ? relation.Parameters["value"] : dvs?.indexOf(relation.Parameters["value"])+1))
            break
          case "setRange": // only for 'number' type target
              if (inputType==='number'&& setFdRange){
                setFdRange({
                  min: parseFloat(relation.Parameters['min']),
                  max: parseFloat(relation.Parameters['max']),
                  unit: relation.Parameters['unit'],
                })
              }
              break
          case "show":
            if (setShow) setShow(true); break
          case "hide":
            if (setShow) setShow(false); break
        }
      } else {
        if (setShow) {
          if (relation.Action === 'hide'){
            setShow(true)
          } if(relation.Action === 'show'){
            setShow(false)
          }
        }
      }
    }
  }
}

export const InputBoxGroupForField = ({attempt, field, fvalues, fields, onValueChange, relations}) => {
  const baseName = `att_${attempt}_${field.FieldName}`
  const inputType = getInputType(field)
  let data = fvalues?.find(v => v.FieldID === field.ID)
  // console.log("fvalues: ", fvalues)
  // console.log("field: ", field)
  // console.log("inputType: ", inputType)
  const [show, setShow] = useState(true)
  const [fdRange, setFdRange] = useState({})
 
  useEffect(() => {
    let ignore = false
    // manage relations
    if (!ignore && relations?.length > 0 && inputType !== ''){
      handleRelations(field, relations, fields, fvalues, onValueChange, setFdRange, setShow)
    }
    return () => {
      ignore = true
    }
  }, [field, fvalues])

  const onInputChange = (e) => {
    onValueChange(field.ID, field.Description, "", 
      inputType==='number'? parseFloat(e.target.value) : e.target.value
    )
  }

  const hasRange = inputType==='number' && Object.keys(fdRange).length>0
  // const propRange = hasRange ? { min: fdRange.min, max: fdRange.max } : {}
  const minRange = hasRange && !isNaN(fdRange.min) ? `min: ${fdRange.min}` : ""
  const maxRange = hasRange && !isNaN(fdRange.max) ? `max: ${fdRange.max}` : ""
  const strRange = minRange + (minRange!== ""&&maxRange!=="" ? ", " + maxRange : maxRange)
  const fieldLabel = hasRange && strRange !=="" ? field.Description +" ("+strRange+" "+fdRange.unit+")" : field.Description

  const handleBlur = (event) => {
    let value = parseFloat(event.target.value);
  
    if (hasRange) {
      if (!isNaN(fdRange.min) && value < fdRange.min) {
        value = fdRange.min;
        alert(`Input value is out of range: ` + fieldLabel)
      }
      if (!isNaN(fdRange.max) && value > fdRange.max) {
        value = fdRange.max;
        alert(`Input value is out of range: ` + fieldLabel)
      }
    }
  
    // Update input field with corrected value
    event.target.value = value;
  };

  if(field && inputType !== '' && show)
  return (
    <>
    <div class="row mb-3">
      {field.FromProvider 
      ? <label class="fw-bold form-label"><FontAwesomeIcon icon={faUserCheck} id="icon-provider-input"/>&nbsp;&nbsp;{fieldLabel}</label>
      : <label class="fw-bold form-label">{fieldLabel}</label>}
      
      <div class="input-group col-12">
        <input type={inputType} id={baseName} class="col-sm-2 form-control form-control-sm mb-1" 
        /*placeholder={strRange}*/ value={data?.Value} onChange={onInputChange}
        onBlur={hasRange ? handleBlur : undefined} // ✅ Validate only when input loses focus
        min={hasRange && !isNaN(fdRange.min) ? fdRange.min : undefined}
        max={hasRange && !isNaN(fdRange.max) ? fdRange.max : undefined}
        />{/*hasRange && fdRange.unit && <span>&nbsp;{fdRange.unit}</span>*/}
      </div>
    </div>
    {/* <ReactJson src={{field}} collapsed={1} /> */}
    <Tooltip anchorSelect="#icon-provider-input" place="right">Check with the provider</Tooltip>
    </>
  )
}

export const CaseReport = ({caseNumber, type, attempt, onSaved, update, status, setStatus}) => {
  const session = useContext(SessionContext)
  const [teams, users] = useTeamAndUser()
  
  const [data, setData] = useState({})
  const [flag, setFlag] = useState(false)
  const refresh = () => setFlag(!flag)
  const reportImageRef = useRef(null)

  const [template, setTemplate] = useState(null)

  //const [templateName, setTemplateName] = useState(type==='Field_Report' ? "ReportTemplate" : "AttemptTemplate")
  const templateTypeId = type==='Field_Report' ? 1 : (type==='Attempt_Report' ? 3 : 0)

  useEffect(() => {

    if (templateTypeId === 0) return

    axios.get(dvSrv + `/api/template/${templateTypeId}/relation`, {withCredentials: true})
    .then(({data}) => {
      // returned data includes Fields and Relations
      // type TemplateWithRelations struct {
      //   TemplateWithFields
      //   Relations []FieldRelation
      // }
      setTemplate(data)
      // setTemplate(data.find(t => t.Name === templateName))
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })}, [templateTypeId])

  const encodedCN = toUrlSafeBase64(caseNumber)
  const [reports, setReports] = useState([])
  useEffect(() => {
    if (attempt !== 0) return
    axios.get(dvSrv + `/api/report/${encodedCN}`, {withCredentials: true})
    .then(({data}) => {
        setReports(data)
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
          session.setData(null)
      } else {console.error("Error: ", error)}
  })}, [caseNumber, update])

  // console.log("template fields: ", template?.Fields)
  const reportUrl = `/api/report/${encodedCN}/att/${attempt}`
  const baseLabel = `att_${attempt}`

  const hideProviderSettings = () =>{
    if (session?.data?.PubAcctSettings){
      let proSetting = session.data.PubAcctSettings.find(acs=>acs.FieldName === "SkipProvidersInMainReport")
      // console.log("provider setting: ", session.data.PubAcctSettings)
      if (proSetting?.Value) return proSetting.Value === 1
    }
    return false
  }

  const hideProvidersFromMain = hideProviderSettings()

  useEffect(() => {
    
    axios.get(dvSrv + reportUrl, {withCredentials: true})
    .then(({data}) => setData(data))
    .catch(error => {
      if (error.response && error.response.status === 401) {
          session.setData(null)
      } else {console.error("Error: ", error)}
  })}, [caseNumber, update, flag])

  const onValueChange = (id, desciption, variables, value) => {
    // console.log("###data: ", data)
    let vs = [...data?.FieldValues] // DON'T point to (ie vs = data.FieldValues), MAKE A COPY!!!
    // console.log("###relation value: ", value)
    // console.log("###field values copy: ", vs)
    let index = vs.findIndex(v => v.FieldID === id)
    if (index === -1) vs.push({FieldID: id, FieldDescription: desciption, FieldValueList: variables, Value: value}) // new data
    else if (vs[index].Value !== value){
      vs[index].FieldDescription = desciption
      vs[index].FieldValueList = variables
      vs[index].Value = value
    } else {
      return
    }
    // console.log("vs: ", vs)
    setData({...data, FieldValues: vs})
  } 

  const fieldNameMap = initFieldMap(template?.Fields)

  const onCommentsChange = (e) => {
    setData({...data, ["Summary"]: e.target.value})
  }

  const onSave = (e) => {
    try {

      axios.post(dvSrv + reportUrl, JSON.stringify({
        ...data,
        TemplateID: template.ID,
        TemplateType: template.Type,
        UserType: template.UserType,
        CaseNumber: caseNumber,
      }), {withCredentials: true})
      .then(({data}) => {
        setData(data)
        onSaved(type, attempt)
        toast.success("Report is saved!")
      })

      if (type==='Field_Report'){
        reportImageRef?.current?.saveImageChange()
      }

    }catch(error){
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}
    }
  }
  const toggleDone = (e) => {
    try {
      let newStatus = data.Status !== ReportStatus.Done ? ReportStatus.Done : ReportStatus.InProgress
      axios.post(dvSrv + `/api/report/${encodedCN}/status`, 
        {Status : newStatus}, {withCredentials: true})
      .then(({}) => {
        setData({...data, ["Status"]: newStatus})
        setStatus(newStatus)
      })

    }catch(error){
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}
    }
  }
  const enabledSortedRels = template?.Relations?.filter(r=>r.Enabled).sort((a,b)=>a.Number-b.Number)
  const enabledFields = template?.Fields?.filter(f=>f.Enabled)
  if (template && data)
  return (    
    <>
    <div class="card" id={`att_${attempt}-rep-card`}>
      {/* <h4 class="card-header text-center">Intubation Report</h4> */}

      <div class="card-body">
        {/* <h5 class="card-title text-center">{attempt===0 && <FontAwesomeIcon icon={faFlag} 
            color={getFlagColor()}/>}&nbsp;&nbsp;Case # {caseNumber}</h5> */}
        {data.UserID ? <p class="card-text">Last modified: {moment(data.UpdatedAt).fromNow()}</p> : ""}
      
        {/*##### when this is attempt report, or case report but there is no saved attempt reports yet #####*/}
        {(attempt > 0 || (attempt ===0 && !hideProvidersFromMain && reports.length < 2)) && <div class="row mb-3">
          <div class="col-6 mb-3">
            <label class="fw-bold form-label">Provider</label>
            <SearchableUserSelector users={users} data={data} setData={setData} />
          </div>      
          <div class="col-6 mb-3">
            <label class="fw-bold form-label">Co-Providers</label>
            {/* <TeamSelector teams={teams} data={data} setData={setData} /> */}
            <SearchableUserSelector users={users} data={data} setData={setData} multi={true}/>
          </div>
        </div>}
        {/*##### when there is case report and there are attempt report(s) #####*/}
        {type==='Field_Report' && !hideProvidersFromMain && reports.length > 1 && 
        reports.sort((a, b) => a.Attempt - b.Attempt).map(rep => rep.Attempt>0 &&
          <div class="row mb-3">      
            <div class="col-6 mb-3">
              <label class="fw-bold form-label">Attempt {rep.Attempt} Provider</label>
              <ul>
                  <li>{GetUserFullname(rep.ProviderID, users)}</li>
              </ul>      
            </div>      
            <div class="col-6 mb-3">
              <label class="fw-bold form-label">Attempt {rep.Attempt} Co-Providers</label>
              <ul>
                {rep.CoProviderIDs?.map(copid => (
                    <li>{GetUserFullname(copid, users)}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {/* <ReactJson src={{data}} collapsed="3" /> */}
      </div>

      <h5 class="card-title text-center mb-3">QA Measurements</h5>
      
      <div class="card-body" id={`${baseLabel}-rep-body`}>
        {enabledFields?.map(field => (field.FieldType==='number' || field.FieldType==='text')
         ? <InputBoxGroupForField attempt={attempt} field={field} fvalues={data.FieldValues} fields={enabledFields}
            onValueChange={onValueChange} relations={enabledSortedRels}/>
         : <RadioButtonGroupForField attempt={attempt} field={field} fvalues={data.FieldValues} fields={enabledFields}
            onValueChange={onValueChange} relations={enabledSortedRels}/>)
        }

        <div class="form-floating">
          <textarea 
            name='review'
            id={`${baseLabel}_summary`}
            class="form-control" 
            style={{ height: '200px' }} 
            value={data.Summary} 
            onChange={onCommentsChange} 
            placeholder="Video Review"
          />
          <label for={`${baseLabel}_summary`}>Comments</label>
        </div>
      </div>
      
      {data && data.CaseNumber && type==='Field_Report' && <ReportImage caseNum={data.CaseNumber} ref={reportImageRef} refresh={refresh}/>}

      <div class="d-grid gap-1 col-6 mx-auto card-body">
        <div class="btn-group" role="group">
          <button onClick={onSave} type="submit" class="btn btn-primary me-3" 
            disabled={!session.data.ReportEdit || status===ReportStatus.Done}>Save</button>
          {attempt===0 && <button onClick={toggleDone} type="submit" class="btn btn-outline-primary" 
            disabled={!session.data.ReportEdit || reports.length===0}>
              {data.Status===ReportStatus.Done ? "Mark Undone" : "Mark Done"}
              {/* &nbsp;<FontAwesomeIcon icon={faFlag} color={data.Status===ReportStatus.Done ? 'orange' : 'green'}/> */}
            </button>}
        </div>   
      </div>

      {/* <div class="card-footer text-muted">
        Report has been locked. Submit new comments as Note below.
      </div> */}
    </div>
    {/* <ReactJson src={{data}} collapsed="3" /> */}
    </>
  )
}

export const CaseReview = ({casenum, type, attUrl, getpostVideoUrlRoot, getpostCaseUrl, updateUrl}) => {
  const [summary, setSummary] = useState("")
  const [FieldValues, setFieldValues] = useState({});
  const [data, setData] = useState({});
  // the review index should be currentReview - 1
  const [currentReview, setCurrentReview] = useState(null)
  const [attributes, setAttributes] = useState([])
  const [changes, setChanges] = useState({});

  const [reviewTypes, setReviewTypes] = useState([]);
  const [selectedReviewType, setSelectedReviewType] = useState(casenum);
  const [videos, setVideos] = useState([])

  const handleSelect = (selectedValue) => {
    // Handle the selected value here
    // console.log("Selected option:", selectedValue);
    resetFieldVals({})
    setData({})
    setCurrentReview(null)
    setSummary("")
    setChanges({})
    setSelectedReviewType(selectedValue)

  };

  const resetFieldVals = () =>{
    attributes
    .filter(config => config.Enabled)
    .map(config => ( FieldValues[config.FieldName] = false ))
  }

  useEffect(() => {
    axios.get(attUrl, {withCredentials: true})
    .then(({data}) => {
      setAttributes(data);
    })
    .catch(error => {
      console.error(error);
    })
  }, [attUrl])
  
  useEffect(() => {
    const encodedId = toUrlSafeBase64(casenum)
    axios.get(dvSrv + `/api/case/${encodedId}`, {withCredentials: true})
    .then(({data}) => {
      setVideos(data.Videos)
      reviewTypes.length = 0
      reviewTypes.push(casenum)

      data.Videos.map(v => {
        //if (!reviewTypes.includes(v.FileName)) {
          //setReviewTypes([...reviewTypes, v.FileName]); // Add new option if it doesn't exist
        //  setReviewTypes((prevOptions) => [...prevOptions, v.FileName]);
          reviewTypes.push(v.FileName)
        //}
      })
    })
    .catch(error => {
       console.error("Error: ", error)
    })}, [casenum])
  
  // Fetch reviews from the server on component mount
  useEffect(() => {
    const foundVideo = videos.find(video => video.FileName === selectedReviewType);
    // console.log(foundVideo);
    let url = foundVideo ? (getpostVideoUrlRoot + foundVideo.ID +"/" + type) : getpostCaseUrl
    // console.log("url: ", url);
    axios.get(url, {withCredentials: true})
    .then(response => 
        {
          setData(response.data)
          if (response.data.result){
              setCurrentReview(response.data.result)
              setSummary(response.data.result.Summary)
          }

          if (response.data.fields && response.data.fields.length > 0){
              for( const val of response.data.values) {
                for (const field of response.data.fields){
                  if(field.ID === val.FieldID){
                    FieldValues[field.FieldName] = (val.Value === "true")
                    break
                }}
              }
              //setFieldValues(tagvals)
          } else { // there is no review or report yet
              resetFieldVals()
          }
        })
    .catch(error => {
      if (error.response.status === 404) {
        //resetTagVals()
      } else { console.error("Error: ", error) }
  })}, [casenum, attributes, selectedReviewType]);

  const handleCheckboxChange = (field, isChecked) => {
    //changes[field] = isChecked
    setChanges(changes => ({
      ...changes,
      [field]: isChecked
    }))
  }

  const onSaveReview = async(e) => {
    e.preventDefault();
    if (changes.length ===0){
        return;
    }
    const formNote = new FormData()
    formNote.append("Summary", summary)    
    for (const [key, value] of Object.entries(changes)) {
    //  console.log(`Key: ${key}, Value: ${value}`);
      formNote.append(key, value)
    }

    if (currentReview === null) { // new review
      const foundVideo = videos.find(video => video.FileName === selectedReviewType);
      let url = foundVideo ? (getpostVideoUrlRoot + foundVideo.ID +"/" + type) : getpostCaseUrl
      axios.post(url, formNote, {withCredentials: true})
        .then(response => {
          //setData(response.data)
          // console.log(response.data);
          for( const val of response.data.values) {
            for (const field of response.data.fields){
              if(field.ID === val.FieldID){
                FieldValues[field.FieldName] = (val.Value === "true")
                break
            }}
          }
          //setFieldValues(tagvals)
          setCurrentReview(response.data.result)
          //setSummary(response.data.result.Summary)
          setChanges({})
          toast.success("Review is saved!")
        })
        .catch(error => console.error("Error posting to server: ", error))
    } else if (currentReview !== null) {
      axios.put(updateUrl+`${currentReview.ID}`, formNote, {withCredentials: true})
        .then(response => {
          setChanges({})
          //setReviews(reviews.map(review => review.ID === currentReview.ID ? { ...review, ...response.data } : review));
          for (const [key, value] of Object.entries(response.data)) {
            //  console.log(`Key: ${key}, Value: ${value}`);
              if (key === "Summary") {
                currentReview.Summary = value
              } else{
                FieldValues[key] = (value === "true")  
              }
            }
            //setData(response.data)
            toast.success("Review is saved!")
        })
        .catch(error => console.error("Error saving result: ", error))
    }
  }

  return (
    
    <>
    <div class="row">

        <ReviewTypeSelector options={reviewTypes} onSelect={handleSelect} />

        {attributes.filter(config => config.Enabled).map((config) => (
        <div key={config.FieldName} class="form-check my-2 align-items-center">
          <input 
            class="form-check-input"
            type="checkbox" 
            checked={config.FieldName in changes ? changes[config.FieldName] : FieldValues[config.FieldName]}
            onChange={(e) => handleCheckboxChange(config.FieldName, e.target.checked)}
          />
          <span>{config.Description}</span>
        </div>
      ))}

      <div class="col-12">
        <div class="form-floating">
          <textarea 
            name='review'
            id='textinput-review'
            class="form-control" 
            style={{ height: '400px' }} 
            value={summary} 
            onChange={e => setSummary(e.target.value)} 
            placeholder="Video Review"
          />
          <label for="textinput-review">Summary</label>
        </div>


      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-6">
        <button onClick={onSaveReview} type="submit" class="btn btn-secondary">Save</button>
      </div>
    </div>
    {/* <ReactJson src={{FieldValues}} collapsed="0" />
    <ReactJson src={{changes}} collapsed="0" />
    <ReactJson src={{currentReview}} collapsed="1" />
    <ReactJson src={{data}} collapsed="0" /> */}
    {/* { <ReactJson src={{changes}} collapsed="1" />}
    { <ReactJson src={{FieldValues}} collapsed="1" />}
    {  <ReactJson src={{data}} collapsed="1" />}
    { <ReactJson src={{selectedReviewType}} collapsed="0" />} */}
    
  </>
  )
}


export const TemplateSelector = ({id, teams, data, setData, options, onSelect}) => {

  const handleSelect = (e) => {
    onSelect(parseInt(e.target.value))
  }

  const [selectedOption, setSelectedOption] = useState(options[0])

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    onSelect(selectedValue); // Call the callback function with the selected value
  };

  return (
    <div>
      <label class="form-label fw-bold me-3">Select Template:</label>
      <select value={id} onChange={handleSelect}>
        <option selected>Pick a template</option>
        {options.map((option, index) => (
          <option key={index} value={option.ID}>
            {option.Name}
          </option>
        ))}
      </select>
    </div>
  );
}

export const ReviewTypeSelector = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]); // Initialize with the first option

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    onSelect(selectedValue); // Call the callback function with the selected value
  };

  return (
    <div>
      <label class="form-label fw-bold me-3">Select Case or Video:</label>
      <select value={selectedOption} onChange={handleSelectChange}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
