import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { dvSrv } from "../App"
import { TimeStringForTimeZone } from "./Setting"
import { SessionContext } from '../App';
import { initFieldMap } from "./utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faEdit, faCopy, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import { Tooltip } from "react-tooltip"
import { toast } from "react-toastify";
import ReactJson from '@microlink/react-json-view';

export const Templates = () => {

  return (
    <>
    <div class="row mt-3">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active internal-tab" id="report-tab" data-bs-toggle="tab" data-bs-target="#field_report" 
          type="button" role="tab" aria-controls="field_report" aria-selected="false">Field Report</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="attempt-tab" data-bs-toggle="tab" data-bs-target="#attempt_report" 
          type="button" role="tab" aria-controls="attempt_report" aria-selected="false">Attempt Report</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="field_report" role="tabpanel" aria-labelledby="report-tab">
          <Template type={1} />
        </div>
        <div class="tab-pane fade" id="attempt_report" role="tabpanel" aria-labelledby="attempt-tab">
          <Template type={3} />
        </div>
      </div>
    </div>
    {/* <ReactJson src={data} collapsed={1} /> */}
    </>
  )
}

export const Template = ({type}) => {
  const session = useContext(SessionContext)
  const [data, setData] = useState()
  const [edit, setEdit] = useState(false)
  const [flag, setFlag] = useState(false)
  
  useEffect(() => {
    axios.get(dvSrv + `/api/template/${type}`, {withCredentials: true})
      .then(({data}) => {
        // TODO sort and apply seq number
        setData(data)
        setEdit(false)
      })
      .catch(error => {
        if (error.response.status === 401) {
          session.setData(null)
        } else { console.error("Error: ", error) }
      })
    }, [flag])

  const handleUpDown = (num, move) => {
    setEdit(true)

    let tfs = [...data.Fields]
    let currentPos = num - 1 // Note: pos is 0-based, num is 1-based.
    let nextPos = currentPos + move

    if (nextPos > 0 && nextPos <= tfs.length) {
      let tmp = tfs[nextPos]
      if (tmp) {
        tfs[currentPos].Number = nextPos+1
        tfs[nextPos] = tfs[currentPos]

        tmp.Number = currentPos+1
        tfs[currentPos] = tmp
      }
    }
    setData({...data, Fields: tfs})
  }

  const toggleEnabled = (num) => {
    let ts = [...data.Fields]
    let index = num - 1
    ts[index].Enabled = !ts[index].Enabled
    setEdit(true)
    setData({...data, Fields: ts})
  }
  const toggleFromProvider = (num) => {
    let ts = [...data.Fields]
    let index = num - 1
    ts[index].FromProvider = !ts[index].FromProvider
    setEdit(true)
    setData({...data, Fields: ts})
  }
  
  const handleValuesChange = (e) => {
    const {name, value} = e.target
    let str = name.split('-')
    let index = parseInt(str[1]) - 1
    let ts = [...data.Fields]
    ts[index][str[0]] = value
    setEdit(true)
    setData({...data, Fields: ts})
  }

  const handleDuplicate = (num) => {
    let tf = [...data.Fields]
    let index = num - 1
    let newtf = {...tf[index], ID: undefined, Number: tf.length+1}
    newtf.FieldName += "_New_"+(tf.length+1)
    newtf.Description = "Enter new description"
    newtf.FieldValues = "Enter 2-3 variables, separated variables by comma"
    setEdit(true)
    setData({...data, Fields: [...tf, newtf]})
  }

  const variableType = ['bool', 'bool+', 'string', 'int', 'number', 'text']
  const onVariableTypeSelect = (num, selectedValue) => {
    let ts = [...data.Fields]
    let index = num - 1
    ts[index].FieldType = selectedValue    
    setEdit(true)
    setData({...data, Fields: ts})
  }

  const handleCancel = () => {
    setFlag(!flag) // revert back to the begining
  }

  // To save the whole table
  const handleApply = () => {
    axios.post(dvSrv + `/api/template/${type}`, data, {withCredentials: true})
      .then(({data}) => {
        setData(data)
        toast.success("Template is saved!")
      })
      .catch(error => {
        if (error.response.status === 401) {
          session.setData(null)
        } else { console.error("Error: ", error) }
      })
    setEdit(false)
  }

  if (data)
  return (
  <>
    <div class="row">
      <div class="col-8">
        <h3 class="mt-3 mb-3">Template Fields</h3>
        <p class="text-muted mb-5">Last modified: {TimeStringForTimeZone(session, data.UpdatedAt)} ({moment(data.UpdatedAt).fromNow()})</p>
      </div>
      <div class="col-4">
        <button class={`btn btn-lg btn-${edit ? "primary" : "outline-secondary"} mt-5`} disabled={!edit} onClick={handleApply}>{edit ? "Apply" : "Applied"}</button>
        {edit && <button class="btn btn-lg btn-warning mt-5 ms-3" disabled={!edit} onClick={handleCancel}>Cancel</button>}
      </div>
    </div>
    <div>
      <table class="table table-hover">
        <thead>
          <th class="text-center text-muted">#</th>
          <th>Name</th>
          <th>Description</th>
          <th>Type</th>
          <th>Variables</th>
          <th class="text-center">Enabled</th>
          <th class="text-center">From Provider</th>
          <th></th>
        </thead>
        <tbody>
          {data.Fields && data.Fields.sort((a,b) => a.Number - b.Number).map(field => <TemplateFieldTab 
            field={field}
            editing={edit}
            toggleEnabled={toggleEnabled}
            toggleFromProvider={toggleFromProvider}
            handleValuesChange={handleValuesChange}
            handleUpDown={handleUpDown}
            handleDuplicate={handleDuplicate}
            variableType={variableType}
            onVariableTypeSelect={onVariableTypeSelect} 
            />)}
        </tbody>
      </table>
    </div>
    <hr />
    <FieldRelations type={type} />      
    {/* <ReactJson src={{data}} collapsed={3} /> */}
  </>  
  )
}

export const TemplateFieldTab = ({field, editing, toggleEnabled, toggleFromProvider, handleValuesChange, handleUpDown, handleDuplicate, variableType, onVariableTypeSelect}) => {

  // set edit mode for an unsaved new field after duplication
  const [edit, setEdit] = useState(field.ID===undefined)

  useEffect(() => {
    if (!editing) setEdit(false)
  }, [editing])

  const onSelect = (selectedValue) => onVariableTypeSelect(field.Number, selectedValue)

  return (
    <>
    <tr>
      <td class="text-center text-muted">{field.Number}</td>
      <td>
        {edit
        ? <input type="text" class="form-control-sm" name={"FieldName-"+field.Number} value={field.FieldName} placeholder="Name" onChange={handleValuesChange}/>
        : field.FieldName
        }
      </td>
      <td>
        {edit
        ? <input type="text" class="form-control-sm" name={"Description-"+field.Number} value={field.Description} placeholder="Description" onChange={handleValuesChange}/>
        : field.Description
        }
      </td>
      <td>
        {edit
        ? <TypeSelector value={field.FieldType} options={variableType} onSelect={onSelect} />
        : field.FieldType
        }
      </td>
      <td>
        {edit
        ? <input type="text" class="form-control-sm" name={"FieldValues-"+field.Number} value={field.FieldValues} placeholder="Variables" onChange={handleValuesChange}/>
        : field.FieldValues
        }
      </td>
      <td class="text-center">
        <input 
          class="form-check-input"
          type="checkbox"
          checked={field.Enabled}
          onChange={() => toggleEnabled(field.Number)}
        />
      </td>
      <td class="text-center">
        <input 
          class="form-check-input"
          type="checkbox"
          checked={field.FromProvider}
          onChange={() => toggleFromProvider(field.Number)}
        />
      </td>
      <td class="text-center">
        <div class="btn-group">
          <button class="btn btn-sm btn-outline-secondary py-0" name="Up" onClick={()=>handleUpDown(field.Number, -1)}><FontAwesomeIcon icon={faArrowUp} /></button>
          <button class="btn btn-sm btn-outline-secondary py-0" name='Down' onClick={()=>handleUpDown(field.Number, 1)}><FontAwesomeIcon icon={faArrowDown} /></button>
        </div>  &nbsp;
        <div class="btn-group" role="group">
          {edit
          ? <button class="btn btn-sm btn-warning py-0" type="button" onClick={()=>setEdit(false)}>Exit</button>
          : <button id="editfield" class="btn btn-sm btn-outline-secondary py-0" onClick={()=>setEdit(true)}><FontAwesomeIcon icon={faEdit} /></button>
          }
          <button id="duplicatefield" class="btn btn-sm btn-outline-secondary py-0" onClick={()=>handleDuplicate(field.Number)}disabled={edit}><FontAwesomeIcon icon={faCopy} /></button>
        </div>
      </td>
    </tr>
    <Tooltip anchorSelect="#editfield" place="top" arrowColor="#222222">Edit Field</Tooltip>
    <Tooltip anchorSelect="#duplicatefield" place="top" arrowColor="#222222">Duplicate Field</Tooltip>
    </>
  )
}

export const TypeSelector = ({value, options, onSelect, label}) => {
  //const [selectedOption, setSelectedOption] = useState(value || options[0])
  let selectedOption = value && value !=="" ? value : options[0]

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value
    selectedOption = selectedValue
    onSelect(selectedValue) // Call the callback function with the selected value
  }

  return (
    <div>
      {label && <label class="form-label fw-bold me-3">{label}</label>}
      <select value={selectedOption} onChange={handleSelectChange}>
        {value && <option value={value} selected>{value}</option>}
        {options.map((option, index) => (
          option !== value && <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
/*
  "TriggerField": "Duration",
  "Condition": "<=",
  "TriggerValue": 30,
  "Action": "setValue",
  "TargetField": "ProlongedAttempt",
  "Parameters": { "value": "No" }
*/
export const FieldRelations = ({type}) => {
  const session = useContext(SessionContext)
  const [data, setData] = useState()
  const [edit, setEdit] = useState(false)
  const [flag, setFlag] = useState(false)
  
  useEffect(() => {
    axios.get(dvSrv + `/api/template/${type}/relation`, {withCredentials: true})
      .then(({data}) => {
        // TODO sort and apply seq number
        setData(data)
        setEdit(false)
      })
      .catch(error => {
        if (error.response.status === 401) {
          session.setData(null)
        } else { console.error("Error: ", error) }
      })
    }, [flag])

  // const handleDelete = (id) => {
  //   let tr = data.Relations.filter(r=>r.ID !== id)
  //   setEdit(true)
  //   setData({...data, Relations: tr})
  // }
  const handleUpDown = (num, move) => {
    setEdit(true)

    let trs = [...data.Relations]
    let currentPos = num - 1 // Note: pos is 0-based, num is 1-based.
    let nextPos = currentPos + move

    if (nextPos > 0 && nextPos <= trs.length) {
      let tmp = trs[nextPos]
      if (tmp) {
        trs[currentPos].Number = nextPos+1
        trs[nextPos] = trs[currentPos]

        tmp.Number = currentPos+1
        trs[currentPos] = tmp
      }
    }
    setData({...data, Relations: trs})
  }

  const getInitialFieldValue =(field) =>{
    if (!field) return ""

    if (field.FieldType === 'number' || field.FieldType === 'text'){
      return ""
    } else {
      let vals = field.FieldValues?.split(',')
      return vals.length>0 ? vals[0] : ""
    }
  }
  
  const handleSelectionChange = (rid, fname, value) => {
    let rs = [...data.Relations]
    // console.log("Relations: ", rs)
    // console.log("Selected reation id: ", rid)
    let index = -1
    for (let i = 0; i < rs.length; i++) {
      if (rs[i].ID === rid){
        index = i
        break
      }
    }
    setEdit(true)
    if (index < 0) return

    switch (fname) {
      // when the sender/trigger field changed, the value is field name, reset Action and Parameters
      case "TriggerFieldID":
        //rs[index].Action = "setValue"
        //rs[index].Parameters = {}
        rs[index].TriggerValue = getInitialFieldValue(fieldNameMap[value])
        rs[index][fname] = fieldNameMap[value].ID
        break
      case "Action":
        if (rs[index].TargetFieldIDs.length === 1){
          let field = data.Fields.filter(f=>f.ID === rs[index].TargetFieldIDs[0])[0]
          //let val = getInitialFieldValue(field)
          if (value === "setRange" && field.FieldType !== 'number'){
            alert("The setRange Action can only be set to a number type field!")
            return
          }
          if (value === "setValue"){
            rs[index].Parameters = {"value": null}
          } else if (value === "setRange"){
            rs[index].Parameters = {"max" : null, "min": null, "unit" : null}
          } else {rs[index].Parameters = {}}
        }
        rs[index][fname] = value
        break
      case "TriggerValue":
      case "TriggerCondition":
      case "Parameters":
        rs[index][fname] = value
        break
      case "TargetFieldIDs":
        if (value.length === 1){
          let field = data.Fields.filter(f=>f.ID === value[0])[0]
          // console.log("field: ", field)
          // console.log("relation: ", rs[index])
          let val = getInitialFieldValue(field)
          if (rs[index].Action === "setRange" && field.FieldType !== 'number'){
            rs[index].Action = "setValue"
            rs[index].Parameters = {"value" : val==="" ? null : val}
          } else if (rs[index].Action === "setValue"){
            rs[index].Parameters = {"value": val==="" ? null : val}
          }
        }
        
        rs[index][fname] = value
        break
      default:
        break
    }
    // console.log("Relations after: ", rs)
    
    setData({...data, Relations: rs})
  }

  const handleNewRelation = () => {
    let names = Object.keys(fieldNameMap) // map for enabled fields
    if (names.length <= 1) {
      alert("Need more enabled fields to create relations!")
      return
    }
    // if there are relations, duplicate the last one
    if (data.Relations.length > 0){
      let tr = data.Relations.sort((a,b) => a.Number-b.Number)
      handleDuplicate(tr[tr.length-1].Number)
      return
    }
// console.log("data: ", data)
    let senderField = fieldNameMap[names[0]]
    let receiverField = fieldNameMap[names[1]]
    let newR = {ID : -1,
      TemplateID: data.ID,
      TriggerFieldID: senderField.ID,
      TriggerCondition: "=",
      TriggerValue:    getInitialFieldValue(senderField),
      Action: "setValue",
      TargetFieldIDs: [receiverField.ID],
      Parameters: {},
      Number: 1
    }
    
    setEdit(true)
    setData({...data, Relations: [newR]})
  }

  const handleDuplicate = (num) => {
    let tr = data.Relations.sort((a,b) => Math.abs(a.ID)-Math.abs(b.ID))
    let origR = tr.filter(r=>r.Number === num)[0]
    // let newtr = {...tf[index], ID: undefined, Number: tf.length+1}
    // let tr = [...data.Relations]
    // let index = num - 1
    let nid = (Math.abs(tr[tr.length-1].ID)+1)*(-1)

    // console.log("new relation id: ", nid)
    let newtr = {...origR, 
                ID: nid, /*Action: "setValue",*/
                Number: tr.length+1,
                Parameters: {...origR.Parameters}} // Parameters also needs to be a copy

    setEdit(true)
    setData({...data, Relations: [...tr, newtr]})
  }

  const toggleEnabled = (num) => {
    let rs = [...data.Relations]
    let index = num - 1
    rs[index].Enabled = !rs[index].Enabled
    setEdit(true)
    setData({...data, Relations: rs})
  }
  
  const actionTypes = ['show', 'hide', 'setRange', 'setValue']
  const conditionTypes = ['>', '=', '<', '>=', '<=']

  const handleCancel = () => {
    setFlag(!flag) // revert back to the begining
  }

  // To save the whole table
  const handleApply = () => {
    let dcopy = {...data}
    for (let i = 0; i < dcopy.Relations.length; i++) {
      if (dcopy.Relations[i].ID < 0){
        dcopy.Relations[i].ID = undefined
      }
    }

    axios.post(dvSrv + `/api/template/${type}/relation`, dcopy, {withCredentials: true})
      .then(({data}) => {
        setData(data)
        toast.success("Template is saved!")
      })
      .catch(error => {
        if (error.response.status === 401) {
          session.setData(null)
        } else { console.error("Error: ", error) }
      })
    setEdit(false)
  }
  
  const fieldNameMap = initFieldMap(data?.Fields)

  if (data)
  return (
  <>
    <div class="row">
      <div class="col-8">
        <h3 class="mt-3 mb-3">Field Relations</h3>
        <span class="badge rounded-pill bg-warning fs-6 text-dark mb-1">Experimental Feature!</span>
        <p class="text-muted mb-5">Last modified: {TimeStringForTimeZone(session, data.UpdatedAt)} ({moment(data.UpdatedAt).fromNow()})</p>
      </div>
      <div class="col-4">
        <button class={`btn btn-lg btn-${edit ? "primary" : "outline-secondary"} mt-5`} disabled={!edit} onClick={handleApply}>{edit ? "Apply" : "Applied"}</button>
        {edit && <button class="btn btn-lg btn-warning mt-5 ms-3" disabled={!edit} onClick={handleCancel}>Cancel</button>}
      </div>
    </div>
    <div>
      <table class="table table-hover">
        <thead>
          {/* <th class="text-center text-muted">#</th> */}
          <th>Sender</th>
          <th class="text-center">Condition</th>
          <th>Value</th>
          <th>Receiver</th>
          <th>Action</th>
          <th>Parameters</th>
          <th>Enabled</th>
          <th class="text-center"><button id="newrelation" class="btn btn-outline-secondary py-0" onClick={()=>handleNewRelation()}><FontAwesomeIcon icon={faPlus} /></button></th>
        </thead>
        <tbody>
          {data.Relations && data.Relations.sort((a,b) => a.Number-b.Number).map(relation => <RelationTab 
            relation={relation}
            editing={edit}
            // handleDelete={handleDelete}
            // handleActionChange={handleActionChange}
            // handleConditionChange={handleConditionChange}
            // handleParametersChange={handleParametersChange}
            handleUpDown={handleUpDown}
            handleSelectionChange={handleSelectionChange}
            handleDuplicate={handleDuplicate}
            toggleEnabled={toggleEnabled}
            conditionTypes={conditionTypes}
            actionTypes={actionTypes}
            // onActionTypeSelect={onActionTypeSelect}
            fieldMap={fieldNameMap}
            />)}
        </tbody>
      </table>
    </div>
    <Tooltip anchorSelect="#newrelation" place="top" arrowColor="#222222">New Relation</Tooltip>      
    {/* <ReactJson src={{data}} collapsed={3} /> */}
  </>  
  )
}

export const RelationTab = ({relation, editing, handleUpDown, handleSelectionChange, 
    handleDuplicate, toggleEnabled, conditionTypes, actionTypes, fieldMap}) => {

  // set edit mode for an unsaved new relation after duplication
  const [edit, setEdit] = useState(relation.ID < 0)

  useEffect(() => {
    if (!editing) setEdit(false)
  }, [editing])

  const onSenderChange = (selectedValue) => {
    // relation.TriggerValue = ""
    handleSelectionChange(relation.ID, "TriggerFieldID", selectedValue)
  }
  const onConditionChange = (selectedValue) => {
    handleSelectionChange(relation.ID, "TriggerCondition", selectedValue)
  }
  const onActionChange = (selectedValue) => {
    handleSelectionChange(relation.ID, "Action", selectedValue)
  }
  const onSenderValueChange = (selectedValue) => {
    handleSelectionChange(relation.ID, "TriggerValue", selectedValue)
  }
  const onReceiverChange = (selectedValues) => { // selectedValues is field name array
    let selIds =[]
    selectedValues.forEach(name=>{
      selIds.push(fieldMap[name].ID)
    })
    handleSelectionChange(relation.ID, "TargetFieldIDs", selIds)
  }
  const onReceiverParametersChange = (selectedValue) => {
    handleSelectionChange(relation.ID, "Parameters", selectedValue)
  }
  const onExitEdit = (relationId) =>{
    if (!relation.Action || relation.Action === ""){
      alert("The Relation Action has to be set!")
      return
    }
    setEdit(false)
  }
  const fieldNames = Object.keys(fieldMap)
  // console.log("fieldMap:", fieldMap)
  // console.log("fieldNames:", fieldNames)
  const getFieldName = (id) => {
    for (const [name, field] of Object.entries(fieldMap)) {
      if (field.ID === id)
        return name
    }
    return ""
  }
  const getFieldNames = (ids) => {
    // console.log("field ids", ids)
    let names = []
    for (const [name, field] of Object.entries(fieldMap)) {
      if (ids.includes(field.ID))
        names.push(name)
    }
    return names
  }
  
  let senderField = getFieldName(relation.TriggerFieldID)
  let receiverFields = getFieldNames(relation.TargetFieldIDs)

  // console.log("Relation: ", relation)
  // console.log("senderField: ", senderField)

  if (senderField !== "")
  return (
    <>
    <tr>
      {/* <td class="text-center text-muted">{relation.Number}</td> */}
      <td>
        {edit
        ? <TypeSelector value={senderField} options={fieldNames} onSelect={onSenderChange} />
        : senderField
        }
      </td>
      <td class="text-center">
        {edit
        ? <TypeSelector value={relation.TriggerCondition} options={conditionTypes} onSelect={onConditionChange} />
        : relation.TriggerCondition
        }
      </td>
      <td>
        {edit
        ? (fieldMap[senderField].FieldType === 'number' || fieldMap[senderField].FieldType === 'text')
                 ? <input type={fieldMap[senderField].FieldType} class="form-control form-control-sm mb-1" 
                          value={relation.TriggerValue} onChange={onSenderValueChange} />
                 : <TypeSelector value={relation.TriggerValue} 
                 options={fieldMap[senderField].FieldValues?.split(',')} onSelect={onSenderValueChange} />
        : relation.TriggerValue
        }
      </td>
      <td style={{ maxWidth: "200px", wordWrap: "break-word", whiteSpace: "normal" }}>
        {edit
        ? <MultiSelector multiple={relation.Action === 'show' || relation.Action === 'hide'} values={receiverFields}
                        options={fieldNames.filter(f=>f.FieldName !== senderField)} onSelect={onReceiverChange} />
        : receiverFields.join(', ')
        }
      </td>
      <td>
        {edit
        ? <TypeSelector value={relation.Action} options={actionTypes} onSelect={onActionChange} />
        : relation.Action
        }
      </td>
      <td>
        {relation.Action !== 'show' && relation.Action !== 'hide' &&
          (edit && relation.Action !=='' && receiverFields?.length === 1 // for "setValue" and "setRange", only one target field is allowed
            ? <FieldParameters field={fieldMap[receiverFields[0]]} action={relation.Action} parameters={relation.Parameters} onChange={onReceiverParametersChange}/>
            : JSON.stringify(relation.Parameters)
          )
        }
      </td>
      <td class="text-center">
        <input 
          class="form-check-input"
          type="checkbox"
          checked={relation.Enabled}
          onChange={() => toggleEnabled(relation.Number)}
        />
      </td>
      <td class="text-center">
        <div class="btn-group">
          <button class="btn btn-sm btn-outline-secondary py-0" name="relUp" onClick={()=>handleUpDown(relation.Number, -1)}><FontAwesomeIcon icon={faArrowUp} /></button>
          <button class="btn btn-sm btn-outline-secondary py-0" name='relDown' onClick={()=>handleUpDown(relation.Number, 1)}><FontAwesomeIcon icon={faArrowDown} /></button>
        </div>  &nbsp;
        <div class="btn-group" role="group">
          {edit
          ? <button class="btn btn-sm btn-warning py-0" type="button" onClick={()=>onExitEdit(relation.ID)}>Exit</button>
          : <button id="editrelation" class="btn btn-sm btn-outline-secondary py-0" onClick={()=>setEdit(true)}><FontAwesomeIcon icon={faEdit} /></button>
          }
          <button id="copyrelation" class="btn btn-sm btn-outline-secondary py-0 me-2" onClick={()=>handleDuplicate(relation.Number)}disabled={edit}><FontAwesomeIcon icon={faCopy} /></button>
          {/* <button class="btn btn-outline-secondary py-0" onClick={()=>handleDelete(relation.ID)}disabled={edit}><FontAwesomeIcon icon={faTrash} /></button> */}
        </div>
      </td>
    </tr>
    <Tooltip anchorSelect="#editrelation" place="top" arrowColor="#222222">Edit Relation</Tooltip>
    <Tooltip anchorSelect="#copyrelation" place="top" arrowColor="#222222">Duplicate Relation</Tooltip>
    </>
  )
}

export const MultiSelector = ({multiple, values, options, onSelect, label}) => {
  const [selectedOptions, setSelectedOptions] = useState(values || [])

  const handleSelectChange = (e) => {
    const { options } = e.target;
    const selVals = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selVals.push(options[i].value);
      }
    }
    setSelectedOptions(selVals);
    onSelect(selVals) // Call the callback function with the selected values
  }

  return (
    <div>
      {label && <label class="form-label fw-bold me-3">{label}</label>}
      <select multiple={multiple} value={multiple ? selectedOptions : (selectedOptions.length>0 ? selectedOptions[0] : null)} 
        onChange={handleSelectChange}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export const FieldParameters = ({field, action, parameters, onChange}) => {
  
  const inputType = field.FieldType==='number' || field.FieldType==='text' ? field.FieldType : ''
  let newParas = parameters
  const onInputChange = (e) => {
    newParas["value"] = e.target.value==="" ? null : e.target.value
    onChange(newParas)
  }
  const onSelectChange = (selVal) => {
    newParas["value"] = selVal
    onChange(newParas)
  }
  const onRangeChange = (key, val) => {
    newParas[key] = val==="" ? null : val
    onChange(newParas)
  }

  if(field && action !== '')
  return (
    <>
    {action==='setValue' && (inputType!=='' 
        ? <input type={inputType} class="form-control form-control-sm mb-1" value={parameters["value"]} onChange={onInputChange} />
        :  <TypeSelector value={parameters["value"]} options={field.FieldValues?.split(',')} onSelect={onSelectChange} />)
    }
    {action==='setRange' && inputType==='number' &&
      <div>
        <div class="row">
          <div class="col-2"><label for="rangemin" class="col-form-label">min</label></div>
          <div class="col-10">
            <input type={inputType} class="form-control" id="rangemin" value={parameters["min"]} onChange={e => onRangeChange("min", e.target.value)} />
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label for="rangemax" class="col-form-label">max</label></div>
          <div class="col-10">
            <input type={inputType} class="form-control" id="rangemax" value={parameters["max"]} onChange={e => onRangeChange("max", e.target.value)} />
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label for="rangeunit" class="col-form-label">unit</label></div>
          <div class="col-10">
              <input type="text" class="form-control" id="rangeunit" value={parameters["unit"]} onChange={e => onRangeChange("unit", e.target.value)} />
          </div>
        </div>
      </div>
        
    }
    </>
  )
}
